.staff_yellow_heading {
    background-color: #FFBA00;
    display: flex;
    padding: 11px 20px;
    align-items: center;
    border-radius: 6px;
    gap: 5px;

    @media screen and (max-width: 768px) {
        margin-top: 16px;
    }

    .staff_alert_text {
        color: #2F3245;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px
    }

    .staff_click_btn {
        color: #2F3245;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-decoration-line: underline;
        white-space: nowrap;

    }
}

.user-profile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    background-color: #8c0346;
    padding: 3px 6px;
    border-radius: 42px;
    max-width: fit-content;
    margin-top: 20px;
    margin-left: 15%;
}

.account_owner_name {
    font-weight: 500;
    font-size: 12px;
    color: white;
    margin: 0;
}