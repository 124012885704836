$dark-blue: #102c42;
$red: #ee4f4f;
@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin textDesign($textColor, $textSize, $textWeight) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
}

.appointment-table-box {
  .appointment-search-box {
    border-radius: 5px;
    border: 1px solid #dadada;
    background: #fff;

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}
.event_shift_header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
}
.sidebar_table_wrapper {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.waiting_filter {
  position: relative;
  @include textDesign($dark-blue, 15px, 600);
  .red_dot {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: $red;
    border-radius: 50%;
    top: 0;

    @media (max-width: 767px) {
      top: 8%;
    }
  }
}

.not_found {
  @include flex(row, center, center);
  width: 100%;
}

.right_side_active {
  padding-left: 20px;
  width: 100%;
  max-width: calc(100% - 270px);
  @media screen and (max-width: 991px) {
    padding-left: 0;
    max-width: 100%;
  }
}
.right_side {
  padding-left: 20px;
  width: 100%;
  @media screen and (max-width: 991px) {
    padding-left: 0;
    max-width: 100%;
  }
}
.appointment-table-row-main-link {
  text-decoration: underline;
  @include textDesign(#587e85, 14px, 500);

  .appointment-table-patient-name {
    text-decoration: underline;
    margin: 0;
  }
}

.details_card {
  padding: 70px !important;
  display: flex;
  flex-direction: row;
  gap: 70px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 30px;
    padding: 20px !important;
  }
  .waiting_list_details_left_section {
    border-right: 1px solid #e0e0e0;
    min-width: 290px;
    padding-right: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 991px) {
      margin-right: 0;
      border: none;
      padding: 0;
    }
    .patient_image {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .waiting_list_details_right_section {
    width: 100%;
    background-color: #f9fcee;
    padding: 20px;
    .title_btn_wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }
}
.grid_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.day_date_grid {
  @extend .grid_container;
  gap: 16px;
}

.btn_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-direction: column;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.waiting_list_date_box {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e5e7df;
  color: #587e85;
  padding: 7px 10px;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
.recall_data_new_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
.recall_data_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 16px;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  .flex_col_container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .day_date_recall_flex {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: baseline;
    }
  }
}
.patientAppointmentDetails-left-part {
  max-width: 420px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    // padding: 35px;
  }

  .appointment-input-box {
    margin-top: 30px;
    width: 50%;
    background-color: #f9fcee;
  }
}
.recall-patient-appointment-details-wrapper {
  display: flex;
  gap: 30px;

  @media screen and (max-width: 991px) {
    display: grid;
  }

  .recall-patientAppointmentDetails-left-part {
    min-width: 340px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      min-width: unset;
      padding: 0;
    }
  }

  .recall-patientAppointmentDetails-right-part-card {
    padding: 30px;
    border-radius: 6px;
    background: #f9fcee;
    border: none;
    width: 100%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding: 20px;
    }
  }
}
.book-slot-new-appointment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
}
.notify-checkbox {
  gap: 42px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }

  .checkbox-container {
    align-items: center;

    .show-checkedbox {
      width: 16px;
      height: 16px;
      border-radius: 3px;
      margin-right: 7px;
    }

    .box1 {
      background-color: #a9cf3d;
    }

    .box2 {
      background-color: #ffffff;
      border: 1px solid #e5e7df;
    }

    .box3 {
      background-color: #e5e7df;
    }

    @include textDesign(#2f3245, 10px, 500);
  }
}
.book-slot {
  .custom-appointment-scheduler-calendar {
    margin: 0px !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    @media screen and (max-width: 768px) {
      border-radius: 10px;
    }
  }
  .appointment-calendar-timezone-separator {
    border-bottom: 1px solid #e1e8da;
  }
}
.common-appointment-btn-container {
  margin-top: 40px;
}
.reminder-plan-modal {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.mobile-text-size {
  font-size: 25px;
  font-weight: 500;
  color: #111b45;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    font-weight: 500;
  }
}
.select-appointment-reminder-dropdown {
  width: 43%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  .custom-dropdown-only {
    margin-bottom: 10px;
  }
}
.reject-yellow-warning-box {
  padding: 10px 20px;
  background-color: #fcc539;
}
.select-appointment-reminder-plan {
  width: 43%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  .appointment-reminder-modal-card-Wrapper {
    margin-top: 40px;
  }
}
.illness-type-save-cancel-btn {
  border: none;
  background-color: white;
  text-decoration: underline;
  @include textDesign(#587e85, 12px, 500);
}
.add-new-member-btn {
  display: flex;
  margin-top: 40px;
}
.details_ui {
  padding: 12px 20px;
  background-color: #fcc539;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.name_date_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.recall_appointment_btn {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 24px;
  margin-top: 40px;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
}
