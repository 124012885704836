.dropdown_container_x7y9z3 {
  width: 76px;
  cursor: pointer;

  .react-select__control {
    background-color: white;
    border: none !important;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    &:focus,
    &:focus-within {
      border: none !important;
      box-shadow: none !important;
    }
  }

  .react-select__menu-list,
  .react-select__menu {
    min-width: 200px;
    cursor: pointer;
    z-index: 4 !important;
  }

  .react-select__single-value {
    color: #111b45 !important;
    font-size: 14px;
    font-weight: 500;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    display: none;
  }

  div.react-select__value-container {
    padding: 0 !important;
  }

  .react-select__option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }
  }
}
.dropdown_label {
  display: flex;
  gap: 8px;
}

.creation-date-option::before,
.due-date-option::before {
  content: "";
  background-image: url("../../assets/images/check_green_icon.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 12px 12px;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.title {
  font-size: 25px;
  font-weight: 500;
  color: #111b45;
}
