$white: #ffffff;
$sky-blue: #66accf;
$parrot-green: #a9cf3d;
$red: #ee4f4f;
$teal: #587e85;
$border-gray: #e0e0e0;
$purple: #2d3245;
$dark-purple: #111b45;
$iron-gray: #535b5f;
$dark-blue: #102c42;
$LightGray: #dbdbdb;
$HeadingColor: #111b45;
$greenColor: #a9cf3d;

@mixin status-styles($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
}

@mixin textDesign($textColor, $textSize, $textWeight) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
}

@mixin flexbox(
  $display: flex,
  $justify-content: flex-start,
  $flex-direction: row,
  $align-items: stretch,
  $gap: 0
) {
  display: $display;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
  align-items: $align-items;
  gap: $gap;
}
.notes_filter {
  position: relative;
  color: #102c42;
  font-size: 15px;
  font-weight: 600;
  .red_dot {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: red;
    border-radius: 50%;
    top: 0;

    @media (max-width: 767px) {
      top: 8%;
    }
  }
}
.manager_dashboard_card {
  padding: 30px !important;
  @media screen and (max-width: 991px) {
    padding: 25px 16px !important;
  }
}
.dashboad_right_container {
  background-color: #f9fcee;
  width: 100%;
  padding: 30px;

  @media screen and (max-width: 991px) {
    padding: 16px;
  }
}

.grid_system {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;

  .box {
    text-align: start;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }

  .box:nth-child(1) {
    grid-column: span 8;
    background-color: white;
    max-height: 496px;

    @media screen and (min-width: 991px) and (max-width: 1440px) {
      min-height: 480px;
    }

    @media screen and (max-width: 991px) {
      min-height: 530px;
    }
  }

  .box:nth-child(2) {
    grid-column: span 4;
    background-color: white;
    max-height: 496px;
    min-height: 496px;
  }

  .box:nth-child(3) {
    grid-column: span 4;
    max-height: 498px;
  }

  .box:nth-child(4) {
    grid-column: span 4;
    background-color: white;
    max-height: 498px;
  }

  .box:nth-child(5) {
    grid-column: span 4;
    background-color: white;
    max-height: 498px;
  }

  @media (max-width: 1439px) {
    grid-template-columns: repeat(2, 1fr);

    .box:nth-child(1) {
      grid-column: span 2;
    }

    .box:nth-child(2),
    .box:nth-child(3),
    .box:nth-child(4),
    .box:nth-child(5) {
      grid-column: span 1;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;

    .box:nth-child(1) {
      grid-column: span 2;
    }

    .box:nth-child(2),
    .box:nth-child(3),
    .box:nth-child(4),
    .box:nth-child(5) {
      grid-column: span 2 !important;
      width: 100%;
    }
  }
}

.Pending {
  @include status-styles(#fcc539, #2d3245);
}

.Cancelled {
  @include status-styles(#b5bac1, #2d3245);
}

.Started {
  @include status-styles(#66accf, #fff);
}

.Accepted {
  @include status-styles(#71d5b9, #2d3245);
}

.Rejected {
  @include status-styles(#ee4f4f, #fff);
}

.InProgress {
  @include status-styles(#3f78ed, #fff);
}

.Completed {
  @include status-styles(#a9cf3d, #fff);
}

.request_approval_container {
  .demo {
    height: 404px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9f7;
    border-bottom: 1px solid #e5e7df;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .nav-tabs {
      border: none;
      width: fit-content;

      .nav-items {
        width: fit-content;
        text-align: center;
        color: $LightGray;
        cursor: pointer;
        @include textDesign($LightGray, 16px, 500);

        & > .active {
          border: none;
          line-height: 25px;
          @include textDesign($HeadingColor, 16px, 600);
          border-bottom: 3px solid $greenColor;
          padding-bottom: 16px;
          padding-top: 16px;
          padding-left: 14px !important;
          padding-right: 14px !important;
          font-size: 14px !important;

          @media screen and (max-width: 576px) {
            font-size: 13px !important;
            color: $HeadingColor;
            font-weight: 500;
          }
        }

        & > .not-active {
          border: none;
          padding-bottom: 16px;
          padding-top: 17px;
          font-size: 14px;
          padding-left: 14px !important;
          padding-right: 14px !important;
          @media screen and (max-width: 576px) {
            color: $LightGray;
            font-size: 13px !important;
            font-weight: 500;
            padding-top: 19px;
          }
        }

        @media screen and (max-width: 576px) {
          color: $LightGray;
          font-size: 13px !important;
          font-weight: 500;
        }
      }

      @media screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 576px) {
      @include textDesign($HeadingColor, 13px, 500);
    }
  }

  @-moz-document url-prefix() {
    .notes_tab_content {
      scrollbar-width: thin;
      scrollbar-color: #587e85 #f1f1f1;
      border-radius: 10px;
    }
  }

  .notes_tab_content {
    max-height: 404px;
    overflow-y: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 5px;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #587e85;
      border-radius: 10px;
    }
  }

  .request_approval_body {
    padding: 16px;
    background-color: #f9fcee;
    border-radius: 6px;
    width: 100%;
    .content_column_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
      margin-top: 12px;
    }
    .name_btn_wrapper {
      @include flexbox(flex, space-between, row, center, 20px);
      .btn_ui {
        font-size: 9px;
        font-weight: 500;
        border: none;
        background-color: #66accf;
        color: white;
        padding: 4px;
        text-transform: capitalize;
        border-radius: 4px;
        line-height: 10px;
      }
    }
    .btn_container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-top: 12px;
    }
  }
}
.mynote_card_wrapper {
  width: calc(100% - 25px);
  background-color: #f9fcee !important;
  margin: 0 8px 8px 16px;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.dropdown_wrapper {
  display: flex;
  justify-content: space-between;
  .btn_date_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 4px;
    }

    .notes_btn {
      border: none;
      padding: 4px;
      border-radius: 4px;
      line-height: 10px;
      background-color: $sky-blue;
      font-size: 9px;
      font-weight: 500;
      color: white;
    }
    .new {
      background-color: $sky-blue;
      color: $white;
    }

    .seen {
      background-color: #dae1e9;
      color: #8495aa;
    }

    .delivered {
      background-color: $parrot-green;
      color: $white;
    }
  }
}
.custom_dropdown_wrapper {
  padding: 8px 20px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  .dropdown_list {
    border-bottom: 1px solid $border-gray;
    padding: 12px 0 !important;
    &:active {
      background-color: $white;
    }

    &:last-child {
      border: none;
    }
    .dropdown_link {
      text-decoration: none;
      font-size: 13px;
      font-weight: 400;
      &:hover {
        color: $parrot-green;
      }
    }
  }
}
.text_icon_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  width: 100%;
}
.text_icon {
  display: flex;
  flex-direction: row;
  gap: 4px;
  img {
    width: 16px;
    height: 18px;
  }
}
.notes_tab_wrapper {
  background-color: $white;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9f7;
    border-bottom: 1px solid #e5e7df;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .nav-tabs {
      border: none;
      width: fit-content;

      .nav-items {
        width: fit-content;
        text-align: center;
        color: $LightGray;
        cursor: pointer;
        @include textDesign($LightGray, 16px, 500);

        & > .active {
          border: none;
          line-height: 25px;
          @include textDesign($HeadingColor, 16px, 600);
          border-bottom: 3px solid $greenColor;
          padding-bottom: 16px;
          padding-top: 16px;
          padding-left: 14px !important;
          padding-right: 14px !important;
          font-size: 14px !important;

          @media screen and (max-width: 576px) {
            font-size: 13px !important;
            color: $HeadingColor;
            font-weight: 500;
          }
        }

        & > .not-active {
          border: none;
          padding-bottom: 16px;
          padding-top: 17px;
          font-size: 14px;
          padding-left: 14px !important;
          padding-right: 14px !important;
          @media screen and (max-width: 576px) {
            color: $LightGray;
            font-size: 13px !important;
            font-weight: 500;
            padding-top: 19px;
          }
        }

        @media screen and (max-width: 576px) {
          color: $LightGray;
          font-size: 13px !important;
          font-weight: 500;
        }
      }

      @media screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 576px) {
      @include textDesign($HeadingColor, 13px, 500);
    }
  }
  @-moz-document url-prefix() {
    .notes_tab_content {
      scrollbar-width: thin;
      scrollbar-color: #587e85 #f1f1f1;
      border-radius: 10px;
    }
  }
  .notes_tab_content {
    overflow-y: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 100%;
    margin-right: 5px;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #587e85;
      border-radius: 10px;
    }
  }
}
.toggle_btn_container {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  .tabs_name_wrapper {
    background-color: #e0e0e0;
    padding: 3px 3px 4px 3px;
    border-radius: 100px;
    .nav-tabs {
      border: none;
      width: 100%;
      .nav-items {
        width: 50%;
        text-align: center;
        color: $LightGray;
        cursor: pointer;
        @include textDesign($LightGray, 12px, 500);

        & > .active {
          border: none;
          @include textDesign($HeadingColor, 12px, 500);
          background-color: white;
          border-radius: 100px;
          color: #587e85;
          padding: 3px 10px !important;
          line-height: 18px !important;
          box-shadow: 0px 3px 8px 0px #0000001f;
          @media screen and (max-width: 576px) {
            @include textDesign($HeadingColor, 13px, 500);
          }
        }

        & > .not-active {
          border: none;
          color: #587e85;
          padding: 3px 10px !important;
          line-height: 18px !important;
          box-shadow: 0px 3px 1px 0px #0000000a;
        }

        @media screen and (max-width: 576px) {
          @include textDesign($LightGray, 13px, 500);
        }
      }

      @media screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 20px;
    @include textDesign($HeadingColor, 13px, 500);
  }
}
.chat_tab_wrapper {
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;

  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9f7;
    border-bottom: 1px solid #e5e7df;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .nav-tabs {
      border: none;
      width: fit-content;

      .nav-items {
        width: fit-content;
        text-align: center;
        color: $LightGray;
        cursor: pointer;
        @include textDesign($LightGray, 16px, 500);

        & > .active {
          border: none;
          line-height: 25px;
          @include textDesign($HeadingColor, 16px, 600);
          border-bottom: 3px solid $greenColor;
          padding-bottom: 16px;
          padding-top: 16px;
          font-size: 14px !important;

          @media screen and (max-width: 576px) {
            font-size: 13px !important;
            font-weight: 500;
            color: $HeadingColor;
          }
        }

        & > .not-active {
          border: none;
          padding-bottom: 16px;
          padding-top: 16px;
          font-size: 14px !important;

          @media screen and (max-width: 576px) {
            font-size: 13px !important;
            border: none;
            padding-bottom: 16px;
            padding-top: 17px;
          }
        }

        @media screen and (max-width: 576px) {
          font-size: 13px !important;
          font-weight: 500;
          color: $LightGray;
        }
      }

      @media screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 576px) {
      font-size: 13px !important;
      font-weight: 500;
      color: $HeadingColor;
    }
  }
  .chat_tab_content {
    height: 437px;
  }
}
.min_width {
  min-width: 4px;
}
.chat_card {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 16px 0 !important;
  margin-bottom: 16px !important;
  .chat_card_content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .profile_text_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      .profile_pic {
        height: 50px;
        width: 50px;
        border-radius: 100px;
        object-fit: cover;
      }
    }
  }
}

.alert_text_wrapper {
  @include flexbox(flex, row, flex-start, center, 6px);
  margin-bottom: 11px;
}

.dropdown_data_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .status_priority_date_wrapper {
    display: flex;
    justify-self: flex-start;
    align-items: center;
    gap: 8px;
    .status_btn {
      font-size: 9px;
      line-height: 10px;
      font-weight: 500;
      border-radius: 4px;
      border: none;
      outline: none;
      padding: 4px;
      text-transform: uppercase;
    }
    .date {
      color: #6f7788;
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}
.circle_priority_text_wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: fit-content;
  padding: 4px;
  border-radius: 4px;
  border: none;

  @media screen and (max-width: 767px) {
    padding: 4px 8px;
  }
}

.circular_dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
}

.priority_text {
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  margin: 0;
}

.high-priority-wrapper {
  background-color: #f2f3ef;
}

.high-priority-dot {
  background-color: #ee4f4f;
}

.high-priority-text {
  color: #ee4f4f;
}

.medium-priority-wrapper {
  background-color: #f2f3ef;
}

.medium-priority-dot {
  background-color: #ff7900;
}

.medium-priority-text {
  color: #ff7900;
}

.low-priority-wrapper {
  background-color: #f2f3ef;
}

.low-priority-dot {
  background-color: #c789c2;
}

.low-priority-text {
  color: #c789c2;
}
.office_details_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
  margin-top: 12px;
}
.comment_tooltip_wrapper {
  @extend .office_details_wrapper;
  .tooltip_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-direction: row;
    .img_container {
      ul {
        margin-top: 12px;
        display: table;
        width: 100%;
        li {
          float: left;
          position: relative;
          margin-left: -6px;

          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }

          &:first-child {
            margin-left: 0;
          }
          .more {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            text-align: center;
            line-height: 27px;
            background-color: #e3edc3;
            border: 1px dashed #8f9bb2;
            font-size: 11px;
            color: #8f9bb2;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }

  .comment_wrapper {
    @extend .tooltip_wrapper;
  }
}
.task_card {
  background-color: #f9fcee !important;
  width: calc(100% - 25px);
  margin-left: auto;
  margin-right: 8px;
  margin-bottom: 8px;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.card_main_heading {
  font-size: 14px;
  font-weight: 600;
  color: #587e85;
  text-decoration: none;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 12px;
  word-break: break-word;
}
.comment_date_wrapper {
  @include flexbox(flex, row, space-between, center, 0);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  .comment_text {
    font-size: 12px;
    font-weight: 400;
    color: #6f7788;
  }
}
@-moz-document url-prefix() {
  .task_card_container {
    scrollbar-width: thin;
    scrollbar-color: #587e85 #f1f1f1;
  }
}
.task_card_container {
  height: 342px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  justify-content: center;
}
@-moz-document url-prefix() {
  .task_card_container_staff {
    scrollbar-width: thin;
    scrollbar-color: #587e85 #f1f1f1;
  }
}
.task_card_container_staff {
  height: 404px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 5px;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #587e85;
    border-radius: 10px;
  }
}
.date_drop_wrapper {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 10px;
  min-width: fit-content;
  padding-left: 16px;
  height: 24px;
  @media screen and (max-width: 991px) {
    margin-left: auto;
  }
}

.title_checkbox_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100% !important;
  white-space: nowrap;
  align-items: center;
  border-right: 1px solid #e0e0e0;
  padding-right: 16px;
}

.scheduler_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9f7;
  padding: 16px 16px 13px 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .checkbox_date_dropdown {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-height: fit-content;

    .checkbox_wrapper {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: flex-end;
      span {
        font-size: 12.5px !important;
      }
    }
  }
}

.monthly_calendar_text {
  font-size: 12px;
}
.padding_right {
  padding-right: 12px;
  @media screen and (min-width: 767px) {
    padding-right: 12px;
  }
}
.chat_height {
  margin-bottom: 1px;
}
.notes_date {
  font-size: 12px;
  font-weight: 300;
  color: #6f7788;
}

.dashboard_channel_chat_signal {
  padding: 14px 20px 14px 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  position: relative;

  .unread_messages_count {
    position: absolute;
    right: 15px;
    top: 7px;
    width: 20px;
    height: 20px;
    background: #ff5757;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
  }
  .profile_text_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .dashboard_preview_image {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;
      .profile_pic {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .preview_dashboard_info {
      width: 100%;
      .preview_dashboard_member_name {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #587e85;
      }
      .preview_dashboard_member_office_name {
        font-size: 10px;
        color: #87928d;
      }
      .preview_dashboard_member_last_message {
        font-size: 12px;
        font-weight: 500;
        color: #2a4642;
        word-break: break-word;
      }
    }
  }
}
.preview_dashboard_date_action_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2px;
  align-items: center;
  .dashboard_date_box {
    color: #87928d;
    font-size: 10px;
    margin-right: 10px;
    white-space: nowrap;
  }
}
.text_icon_one {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  img {
    width: 12px;
    height: 12px;
  }
}
.header_wrapper {
  .nav-link {
    padding: 0.5rem 14px !important;
  }
}
.request_approval_card {
  background-color: #f9fcee;
  padding: 12px 16px;
  border-radius: 10px;
  .invitation_image_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    gap: 12px;

    .invitation_text {
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      color: #6f7788;
    }
  }
  .action_buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    gap: 24px;
    .accept_reject_button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      border: none;
      outline: none;
      background-color: transparent;
      .btn_text {
        font-size: 10px;
        line-height: 12px;
        color: #87928d;
        margin: 0;
        letter-spacing: -0.2px;
      }
    }
  }
}
.request_approval_card_container {
  @include flexbox(flex, flex-start, column, flex-start, 8px);
  margin-left: 16px;
  margin-right: 8px;
}
.notificard_wrapper {
  padding: 16px;
  background-color: #f9fcee;
  border-radius: 10px;
  width: 100%;
  @include flexbox(flex, flex-start, row, flex-start, 16px);
  button {
    border: none;
    outline: none;
    background: transparent;
  }
}
.notificard_card_wrapper_col {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title_change_btn_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .change_btn_wrapper {
    display: flex;
    flex-direction: column;
    color: #000000;
    align-items: flex-end;

    p {
      margin: 0;
    }
    span {
      font-weight: 600;
    }
    button {
      border: none;
      outline: none;
      max-width: fit-content;
      cursor: pointer;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
.toggle_btn_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background-color: #f9fcee;
  border-radius: 6px;
  margin-bottom: 40px;
  margin-top: 16px;
}

.filter_search_icon_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-top: 8px;

  .new_search_bar {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 3px 10px;
    max-width: calc(100% - 50px);

    .search_input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      padding: 3px 10px;
      max-width: calc(100% - 20px);
    }

    .search_input::placeholder {
      color: #979d9f;
    }
  }
}
.flex_col_div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.select_container_new {
  background-color: #e6eced;
  padding: 8px;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: -9px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #e6eced;
  }
}
.react_select_box {
  width: 100% !important;
  border: 1px solid #e0e0e0;
  cursor: pointer !important;

  :global(.react-select__control) {
    min-height: 32px !important;
  }
}

.filter_icon {
  min-height: 34px;
  min-width: 34px;
}

.closed_btn {
  font-size: 10px;
  color: #fff;
  background: linear-gradient(135deg, #ff4d4d, #d60000);
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 600;
  margin: 0 8px 0 0;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.redeirection_icon {
  min-width: 24px;
  border-left: 1px solid #e0e0e0;
  padding-left: 16px;
}
.mobile_header_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background-color: #f8f9f7;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .mobile_header_first_row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
.dropdownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e0e0e0;
  padding-right: 16px;
}
.edit_icon {
  border: none;
  outline: none;
  background-color: transparent;
}

.btn_container_edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  .inner_btn_container {
    display: flex;
    gap: 20px;
    width: 81%;

    button {
      width: 50%;
    }
  }
}

.flex_req_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btn_ui_new {
  font-size: 9px;
  font-weight: 500;
  border: none;
  padding: 4px;
  text-transform: capitalize;
  border-radius: 4px;
  line-height: 10px;
}

%button-styles {
  @extend .btn_ui_new;
  color: white;
  text-transform: uppercase;
}

.edit_timesheet_btn_new {
  background-color: #3f78ed;
  @extend %button-styles;
}

.timesheet_btn_new {
  background-color: #66accf;
  @extend %button-styles;
}

.leave_btn_new {
  background-color: #fcc539;
  color: black;
  @extend %button-styles;
}
.checkbox_wrapper_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 8px;

  .check_box_width {
    min-width: 20px;
  }
  .checkbox_height {
    min-height: 20px;
    margin-top: 4px;
    padding-left: 20px;
  }
  .name_office {
    display: flex;
    flex-direction: column;
    .office_name {
      word-break: break-word;
    }
  }
  .popup_patient_image {
    height: 40px;
    width: 40px;
    border-radius: 12px;
  }
}
.dashboard_card_filter_container {
  @media screen and (min-width: 768px) {
    display: flex;
    gap: 30px;
  }
}
.dashboard_calendar {
  :global(.scheduler-calendar) {
    margin-bottom: 40px !important;
  }
}
.rounded_container {
  border-radius: 10px;
  height: 100%;
}

.loader_style {
  margin: 0 auto;
  padding: 10px;
}

.transferred_notes_container {
  min-height: 404px;
  height: 100%;
}

.transferred_notes_loading {
  @include flexbox(flex, center, row, center);
}

.transferred_notes_empty {
  @include flexbox(flex, center, row, center);
}
.request_and_approval_container {
  min-height: 404px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}

.request_and_approval_centered {
  @include flexbox(flex, center, row, center);
}

.request_and_approval_loading {
  @include flexbox(flex, center, row, center);
  margin-left: auto;
  margin-right: auto;
}

.request_and_approval_notification_container {
  height: 404px;
  @include flexbox(flex, center, row, center);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.height_404 {
  height: 404px;
}
.no_invitations {
  @include flexbox(flex, center, row, center);
  height: 404px;
}
.border_top_left_right {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.flex_inline_block {
  display: inline-block;
}

.my_task_container {
  height: 100%;
  display: flex;
  justify-content: center;

  &.my_task_loading {
    align-items: center;
  }

  &.no_tasks {
    align-items: center;
  }
}
.chatTabContent {
  height: 100%;
}

.patientChat {
  @include flexbox(flex, center, row, center);
  height: 100%;
}
.patientChat_new {
  max-height: 415px;
  min-height: 415px;
  @include flexbox(flex, center, row, center);
}

.otherChat {
  max-height: 372px;
  min-height: 372px;
  @include flexbox(flex, center, row, center);
}
.otherChat_new {
  max-height: 372px;
  min-height: 372px;
  @include flexbox(flex, center, row, center);
}

.chatPane {
  height: 100%;
  width: 100% !important;
}

.subscriptionModel,
.notAccessible {
  @include flexbox(flex, center, row, center);
  height: 100%;
}
.ellipsis_icon {
  min-width: 36px;
}
