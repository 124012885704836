@use "messenger.scss";

.miraxis_new {
  max-height: 415px;
  .sendbird-conversation__scroll-container {
    max-height: calc(367px - 140px) !important;
    min-height: 170px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
.miraxis_new1 {
  max-height: 437px;
  .sendbird-conversation__scroll-container {
    max-height: calc(367px - 79px) !important;
    min-height: 170px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
.miraxis_new {
  .sendbird-channel-list {
    width: calc(100% + 30px);
    height: 365px;
  }
}
.miraxis_new1 {
  .sendbird-channel-list {
    width: calc(100% + 30px);
    height: 365px;
  }
}
.channel-list-header {
  margin: 0 !important;
}
.miraxis_new {
  .sendbird-conversation__footer {
    position: absolute !important;
  }
}

.miraxis_new .conversation-wrapper .sendbird-conversation__footer {
  z-index: 1;
}
@media screen and (max-width: 991px) {
  .miraxis_new .conversation-wrapper .sendbird-conversation {
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .miraxis_new header {
    padding: 0 !important;
  }
}
.toggle_btn_wrapper .toggle-switch {
  top: 0 !important;
}
.toggle_btn_wrapper
  .toggle-switch
  .toggle-switch-label
  .toggle-switch-inner:after {
  background-color: #a9cf3d;
}
.dashboard_chat_header {
  position: relative;
  top: 0;
  padding: 0;
  z-index: 1;
}
