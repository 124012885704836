$red: #ee4f4f;

.staff-availability-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: 991px) {
    display: block;
  }
  > div {
    margin: 0;
    > label {
      position: absolute;
    }
  }
  select {
    border: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 15px !important;
    padding-top: 0px !important;
  }
  .view-color-list {
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      margin-right: 10px;
      align-items: center;
      @media screen and (max-width: 1024px) {
        width: 30%;
        margin-bottom: 8px;
      }
      @media screen and (max-width: 575px) {
        width: 50%;
        margin: 0 0 8px;
      }
      .text-box {
        font-weight: 400;
        font-size: 10px;
        color: #87928d;
      }
      .color-box {
        background: #f9fcee;
        border: 1px solid #e5e7df;
        border-radius: 2px;
        width: 12px;
        height: 12px;
        margin-right: 3px;
        &.color-blue {
          background: #e1f4fd;
        }
        &.color-orange {
          background: #fef3d7;
        }
        &.color-red {
          background: #fdeded;
        }
        &.color-grey {
          background: #f2f3ef;
        }
        &.light-purple-bg {
          background: #f6f1fd;
        }
        &.color-green {
          background: #d0d7d9;
        }
      }
    }
  }
}

.dynamic_height {
  @media (max-width: 991px) {
    max-height: 475px !important;
    height: 100% !important;
  }
}

.notes_filter {
  position: relative;
  font-size: 14px;
  font-weight: 600;

  .red_dot {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: $red;
    border-radius: 50%;
    top: 0;

    @media (max-width: 767px) {
      top: 8%;
    }
  }
}
