.events-page {
  background-color: #587e85;

  .banner-container {
    position: relative;

    .events-banner {
      min-height: 458px;
      width: 100%;
      object-fit: cover;
    }

    .events-banner1 {
      min-height: 258px;
      width: 100%;
      object-fit: cover;
    }
  }

  .banner-event-heading {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 56px;
    font-weight: 700;
    color: #587e85;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }

  .latest-card-box {
    position: absolute;
    top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 35px;

    @media screen and (max-width: 767px) {
      top: 10%;
    }

    @media screen and (max-width: 1400px) {
      gap: 10px;
    }

    .latest-event-icon {
      max-width: 80px;

      @media screen and (max-width: 1400px) {
        max-width: 70px;
      }

      @media screen and (max-width: 1024px) {
        max-width: 60px;
      }
    }

    .latest-event-heading {
      font-size: 56px;
      font-weight: 700;
      color: #587e85;

      @media screen and (max-width: 1400px) {
        font-size: 45px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
      }

      @media screen and (max-width: 375px) {
        font-size: 19px;
      }
    }

    .latest-event-sub-heading {
      font-size: 18px;
      font-weight: 400;
      color: #2d3245;
      text-align: center;
    }

    .latest-event-detail {
      display: flex;
      flex-direction: row;
      font-size: 20px;
      font-weight: 600;
      color: #2d3245;
      gap: 50px;

      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 16px;
        align-items: flex-start;
      }

      .custom-dot {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #587e85;
        margin-right: 10px;
      }
    }

    .event-button-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .view-event-detail {
        font-size: 18px;
        font-weight: 600;
        color: #587e85;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .latest-custom-events-button button {
        font-weight: 600;
        font-size: 18px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          width: 100% !important;
        }
      }
      .latest-custom-events-button {
        @media screen and (max-width: 767px) {
          width: calc(100% - 40px) !important;
          font-size: 14px;
        }
      }
    }
  }

  .event-card {
    position: relative;
    background: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
    border-radius: 18px;
    font-size: 28px;
    line-height: 1.66;
    color: #2d3245;
    padding: 40px;
    margin-bottom: 60px;

    z-index: 1;
    display: flex;

    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
    }

    .event-details,
    .event-aside {
      flex: 1;
    }

    .event-details {
      display: flex;
      border-right: 1px solid #e6e6e6;
      padding-right: 30px;

      .event-icon {
        width: 80px;
        @media screen and (max-width: 767px) {
          width: 60px;
        }
        img {
          max-width: fit-content;
          @media screen and (max-width: 767px) {
            width: 60px;
          }
        }
      }

      .event-wrapper {
        display: flex;
        flex-direction: column;

        .event-content {
          flex: 1;
          padding-left: 10px;
          margin-bottom: 14px;

          .events-link {
            color: #2f3245;
            font-weight: 500;
            font-size: 26px;

            @media screen and (max-width: 767px) {
              font-weight: 500;
              font-size: 20px;
            }
          }
        }
        .asm-subheading {
          font-size: 18px;
          font-weight: 400;
        }

        .event-image {
          margin-top: 8px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      @media screen and (max-width: 992px) {
        border-right: unset;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 30px;
        padding-right: 0;
      }
    }

    .event-aside {
      display: flex;
      flex-direction: column;
      padding-left: 30px;

      @media screen and (max-width: 767px) {
        gap: 20px;
        padding-left: unset;
      }

      .event-date {
        font-weight: 600;
        font-size: 36px;
        color: #2f3245;

        @media screen and (max-width: 767px) {
          font-size: 22px;
        }
      }

      .aside-content-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .booth-detail {
          display: flex;
          align-items: center;
          white-space: no-wrap;

          .calendar-icon-box {
            margin-right: 10px;
            max-width: 48px;

            @media screen and (max-width: 767px) {
              width: 32px;
            }

            img {
              max-width: 100%;
            }
          }

          .booth-id {
            font-size: 22px;
            font-weight: 500;
            color: #587e85;

            @media screen and (max-width: 767px) {
              font-size: 16px;
            }
          }
        }

        @media screen and (max-width: 992px) {
          flex-direction: row;
        }

        .custom-events-button button {
          @media screen and (max-width: 767px) {
            font-size: 11px;
          }
          .arrow-outward {
            margin-left: 10px;
            max-width: 12px;
            @media screen and (max-width: 767px) {
              max-width: 8px;
            }
          }
        }
      }

      .past-event-etail {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.card-container {
  padding-top: 10px;

  .upcoming-card-container {
    margin-top: -120px;

    @media screen and (max-width: 1200px) {
      font-size: 11px;
      margin-top: -80px;
    }
  }

  .past-card-container {
    .past-card-heading {
      margin-top: 60px;
      margin-bottom: 30px;
      text-align: center;
      color: #ffffff;
      font-size: 46px;
      font-weight: 600;
    }
  }
}
