@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin textDesign($textColor, $textSize, $textWeight) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
}
.sidebar_table_wrapper {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.right_side_active {
  padding-left: 20px;
  width: 100%;
  max-width: calc(100% - 270px);

  @media screen and (max-width: 991px) {
    padding-left: 0;
    max-width: 100%;
  }
}
.right_side {
  padding-left: 20px;
  width: 100%;

  @media screen and (max-width: 991px) {
    padding-left: 0;
    max-width: 100%;
  }
}
.name {
  white-space: nowrap;
  color: #535b5f;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.title_btn_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.new_message_card {
  padding: 70px !important;
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 20px !important;
  }
  .new_message_wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 33%;
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
    .select_patient_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #c4c4c3;
      padding-bottom: 10px;
      .link {
        border: none;
        background-color: white;
      }
    }
  }
}
.link {
  border: none;
  background-color: #f9fcee;
}
.patient_modal_data {
  margin-top: 30px;
  max-height: 255px;
  overflow-y: scroll;
  padding-right: 12px;
  .modal_checkbox_border {
    padding-top: 12px;
    border-bottom: 1px solid #c4c4c3;
    padding-bottom: 5px;
  }
}

.patient_radion_btn_wrapper {
  margin-top: 30px;
  max-height: 217px;
  overflow-y: scroll;
  padding-right: 12px;
  .modal_checkbox_border {
    padding-top: 12px;
    border-bottom: 1px solid #c4c4c3;
    padding-bottom: 5px;
    &:first-child {
      padding-top: 0;
    }
  }
}
.new_search_bar {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 7px 10px;
  margin-top: 30px;
  @media (max-width: 767px) {
    margin-top: 20px;
    max-width: 100%;
  }
}

.search_input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 10px;
}

.search_input::placeholder {
  color: #979d9f;
}

.search_icon {
  @include flex(row, center, center, 20px);
  padding: 5px;
}

.search_icon img {
  width: 16px;
  height: 16px;
}

@media (max-width: 767px) {
  .search-bar {
    margin-bottom: 20px;
  }
}
.illness-type-save-cancel-btn {
  border: none;
  background-color: white;
  text-decoration: underline;
  @include textDesign(#587e85, 12px, 500);
}

.message_details_right_side {
  width: 100%;
  .bg_wrapper {
    padding: 30px;
    background-color: #f9fcee;
    @media screen and (max-width: 768px) {
      padding: 15px;
    }
  }
  .details_checkbox {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 15px !important;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }
  }

  .message_details_com_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .message_details_wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 6px;
      border-bottom: 1px solid #c4c4c3;
      .edit_preview_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.preview_modal_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
  gap: 5px;
}
.view_template_small_card_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  .view_template_msg_container {
    background-color: #f9fcee;
    padding: 20px;
    .download_btn_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      .icon_text {
        display: flex;
        align-items: center;
        gap: 10px;
        .document_icon {
          max-width: 12px;
          max-height: 15px;
        }
      }
    }
  }
}
.download_btn {
  border: none;
  outline: none;
  background: transparent;
  color: #587e85;
  text-decoration: underline;
  margin-top: 8px;
}
