@-moz-document url-prefix() {
  .filter-list {
    scrollbar-width: thin;
    scrollbar-color: #587e85 #e0e0e0;
  }
}

.filter-list {
  max-height: 200px;
  overflow-y: auto;
  &.employees-list {
    max-height: 260px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #e0e0e0;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #587e85;
    border-radius: 10px;
  }

  li {
    padding: 7px 0;
    label {
      margin: 0;
      padding: 0;
    }
    .ch-checkbox,
    .ch-radio {
      line-height: 1.3;
      span {
        font-weight: 500;
        animation: none;
        display: block;
        color: #2f3245;
        word-break: break-word;
        small {
          display: block;
          font-weight: 400;
          font-size: 13px;
          color: #79869a;
        }
      }
    }
  }
}
.spacing_hr {
  margin-top: 9px;
  margin-bottom: 9px;
}
