.add-event-title {
  margin-top: -15px;
}
.event-time-picker {
  display: block;
  .rc-time-picker-input {
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: solid 1px rgba(151, 151, 151, 0.4);
    padding-bottom: 15px;
    box-shadow: none;
    color: #102c42;
    padding-left: 0;
    font-size: 14px;
    outline: 0;
    font-weight: 600;
    height: auto;
  }
}
.event-alert-box {
  background: #fcc539;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.4;
  color: #2f3245;
  margin-bottom: 30px;
  margin-top: -20px;
  border: 0;
  border-radius: 0;
}

.input-tag-group {
  .form-control {
    border: 0;
    border-bottom: solid 1px rgba(151, 151, 151, 0.4);
    height: auto;
    .input-tags {
      border: 0 !important;
      outline: 0;
      width: 100% !important;
    }
    .badge {
      background-color: #f9fcee !important;
      border-radius: 14px;
      margin: 0 5px 5px 0;
      font-size: 12px;
      font-weight: 600;
      color: rgb(47, 50, 68);
      outline: 0;
      padding: 8px 5px 8px 12px;
      button {
        padding: 0 5px;

        margin-left: 4px;
      }
    }
  }
}

.modal-employee-list .ch-checkbox {
  span {
    &::before {
      top: 11px;
    }
    &.assign-order-number {
      &::before {
        top: 1px;
      }
    }
  }
  input[type="checkbox"]:checked + span::after,
  input[type="checkbox"]:checked ~ span::after {
    top: 14px;
  }
  label {
    display: flex;
    margin: 0;
    img {
      width: 40px;
      height: 40px;
      border-radius: 9px;
      object-fit: cover;
      margin-right: 15px;
    }
  }
}
@media (max-width: 767px) {
  .add-event-btn-box {
    text-align: center;
    button {
      width: 100%;
      &.button-dark {
        border: 0;
        width: auto;
        text-decoration: underline;
        margin-top: 15px;
      }
    }
  }
}

.add-role-accordion {
  margin-bottom: 40px;
  .accordion__item {
    margin-bottom: 22px;
    box-shadow: none;
  }
  .accordion__button {
    padding: 4px 30px 4px 0;
    &::before {
      top: 15px;
      right: -8px;
    }
  }
  .accordion__panel {
    padding: 0;
  }
}
.ch-radio {
  label {
    padding: 10px 0px;
  }
  input[type="radio"] {
    left: 4px;
    top: -2px;
  }
}
.custom-input-tags-style {
  font-size: 14px;
  font-weight: 600;
  color: #102c42;
}
