$border-gray: #e0e0e0;
$card-yellow: #f9fcee;
$dark-black: #000000;

@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin textDesign($textSize, $textWeight, $textColor) {
  font-size: $textSize;
  font-weight: $textWeight;
  color: $textColor;
}

.select_staff_modal {
  max-width: 700px;
}
.sub_title {
  @include textDesign(14px, 300, $dark-black);
  margin: 0;
}

.details_sub_title {
  @include textDesign(14px, 300, $dark-black);
  margin-top: -10px;
}

.notice_parent_card {
  padding: 70px !important;
  margin-top: 30px;
  @media (max-width: 991px) {
    padding: 20px !important;
  }
}

.add_notice_card {
  padding: 70px !important;
  width: 100%;
  @media (max-width: 991px) {
    padding: 20px !important;
  }
  .add_new_notice {
    width: 43%;
    @media (max-width: 991px) {
      width: 100%;
    }
    .border_bottom {
      border-bottom: 1px solid $border-gray;
      padding-bottom: 15px;
    }
    .checkbox_wrapper_new {
      margin-top: 30px;
    }
    .textarea_wrapper {
      margin-top: 20px;
    }
  }
}

.btn_wrapper {
  @include flex(row, flex-start, center, 25px);
  margin-top: 40px;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.title_btn_wrapper {
  @include flex(row, space-between, center, 4px);
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }
}

.grid_container {
  display: grid;
  gap: 30px;
  width: 100%;
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.notices_card {
  padding: 30px !important;
  background-color: $card-yellow !important;
  width: 100%;
  .title_date_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 100%;
    .title_date_wrapper {
      @include flex(column, flex-start, flex-start, 4px);
    }
  }
}

.checkbox_wrapper {
  @include flex(row, flex-start, center, 15px);
  padding-bottom: 16px;
  border-bottom: 1px solid $border-gray;
  margin-bottom: 16px;
  .checkbox_height {
    min-height: 20px;
    margin-top: 4px;
    padding-left: 20px;
  }
  .name_office {
    display: flex;
    flex-direction: column;
  }
  .popup_patient_image {
    height: 40px;
    width: 40px;
    border-radius: 12px;
  }
}

.alert_popup_content_wrapper {
  @include flex(column, center, center);
  width: 75%;
  text-align: center;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.details_notice_card {
  padding: 70px !important;
  width: 100%;
  margin-top: 30px;
  @media (max-width: 991px) {
    padding: 20px !important;
    margin-top: 20px;
  }
  .notice_details {
    @include flex(column, flex-start, flex-start, 30px);
    width: 46%;
    @media (max-width: 991px) {
      width: 100%;
    }
    .detail_label {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      color: #6f7788;
      margin: 0;
    }

    .detail_value {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #102c42;
      margin: 0;
      padding-top: 10px;
      word-break: break-word;
    }
  }
}
