$red: #ee4f4f;

.event-shift-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  button {
    line-height: 1.2;
    height: auto;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .search-box {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 5px;
    width: 100%;
    margin-right: 20px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 12px;
    }
    input {
      height: 52px;
    }
    span {
      animation: none !important;
      top: 12px;
    }
  }
}
.event-shift-view {
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 11px;
  z-index: 1;
  @media screen and (max-width: 991px) {
    position: static;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  select {
    border: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 15px !important;
    padding-top: 0px !important;
    cursor: pointer;
  }
}

.notes-modal-dialog {
  .add-notes-field {
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    textarea {
      height: 110px;
    }
  }
  button {
    min-width: 115px;
  }
  .top-icons {
    position: absolute;
    right: 70px;
    top: 30px;
    padding-right: 15px;
    border-right: 1px solid #dadce0;
    span {
      padding: 0px 5px;
      cursor: pointer;
      margin-left: 20px;
      position: relative;
      z-index: 2;
    }
    @media screen and (max-width: 767px) {
      right: 50px;
      top: 15px;
      border-right: 1px solid #dadce0;
      padding-right: 20px;
    }
    img {
      width: 14px;
      height: auto;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 767px) {
    .modal-body {
      margin-top: 30px;
    }
  }
}

@media (max-width: 767px) {
  .add-event-btn {
    display: block;
    width: 100%;
    button {
      width: 100%;
    }
  }
}

.delete-event-modal-dialog {
  max-width: 660px;
  button {
    min-width: 220px;
    @media screen and (max-width: 767px) {
      min-width: auto;
      margin-bottom: 20px;
    }
  }
}

.notes_filter {
  position: relative;
  font-size: 14px;
  font-weight: 600;

  .red_dot {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: $red;
    border-radius: 50%;
    top: 0;

    @media (max-width: 767px) {
      top: 8%;
    }
  }
}
