$white: #ffffff;
$sky-blue: #66accf;
$parrot-green: #a9cf3d;
$red: #ee4f4f;
$teal: #587e85;
$border-gray: #e0e0e0;
$purple: #2d3245;
$dark-purple: #111b45;
$iron-gray: #535b5f;
$dark-blue: #102c42;
$cultured-Pearl: #f9faf6;

@mixin font-properties($font-size, $font-weight, $color) {
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

@mixin flexbox-properties(
  $flex,
  $flex-direction,
  $justify-content,
  $align-items,
  $gap
) {
  display: $flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
  gap: $gap;
}

@mixin status-styles($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
}

.task_management_main_parent_wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .btn_heading_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 0);
    margin-bottom: 30px;

    @media (max-width: 991px) {
      @include flexbox-properties(flex, column, flex-start, flex-start, 0);
    }

    .heading {
      @include font-properties(25px, 500, #111b45);
    }

    .btn_wrapper {
      @include flexbox-properties(flex, row, space-between, center, 20px);

      @media screen and (max-width: 767px) {
        @include flexbox-properties(
          flex,
          column,
          space-between,
          flex-start,
          20px
        );
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.wrapper_card {
  // padding: 72px 59px !important;

  .dropdown_radio_search_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 10px);
    @media screen and (max-width: 1166px) {
      @include flexbox-properties(flex, column, flex-start, flex-start, 0);
    }
    .dropdown_radio_wrapper {
      @include flexbox-properties(flex, row, flex-start, center, 34px);
      @media screen and (max-width: 991px) {
        @include flexbox-properties(flex, column, flex-start, left, 0);
        width: 100%;
      }
    }

    .priority_text_radio {
      @include flexbox-properties(flex, row, flex-start, center, 8px);
      @media screen and (max-width: 991px) {
        @include flexbox-properties(flex, column, flex-start, left, 0);
        width: 100%;
        margin-top: 20px;
        align-items: flex-start;
      }

      .radio_btn_wrapper {
        @include flexbox-properties(flex, row, flex-start, center, 0);

        @media screen and (max-width: 991px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 18px;
          width: 100%;
          margin-top: 16px;
          margin-bottom: 20px;
        }
        .radio_btn_heading {
          @include font-properties(14px, 500, #77928b);
          margin-bottom: 0;
          margin-right: 8px;
        }
      }
    }
  }
}

// CSS for Card

.task_management_card_btn {
  font-size: 9px;
  font-weight: 500;
  line-height: 10px;
  padding: 4px;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    padding: 4px 8px;
    line-height: 18px;
  }
}

.Pending {
  @include status-styles(#fcc539, #2d3245);
}

.Cancelled {
  @include status-styles(#b5bac1, #2d3245);
}

.Started {
  @include status-styles(#66accf, #fff);
}

.Accepted {
  @include status-styles(#71d5b9, #2d3245);
}

.Rejected {
  @include status-styles(#ee4f4f, #fff);
}

.InProgress {
  @include status-styles(#3f78ed, #fff);
}

.Completed {
  @include status-styles(#a9cf3d, #fff);
}

.circle_priority_text_wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: fit-content;
  padding: 4px;
  border-radius: 4px;
  border: none;

  @media screen and (max-width: 767px) {
    padding: 4px 8px;
  }
}

.circular_dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
}

.priority_text {
  font-size: 10px;
  font-weight: 500;
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.high-priority-wrapper {
  background-color: #f2f3ef;
}

.high-priority-dot {
  background-color: #ee4f4f;
}

.high-priority-text {
  color: #ee4f4f;
}

.medium-priority-wrapper {
  background-color: #f2f3ef;
}

.medium-priority-dot {
  background-color: #ff7900;
}

.medium-priority-text {
  color: #ff7900;
}

.low-priority-wrapper {
  background-color: #f2f3ef;
}

.low-priority-dot {
  background-color: #c789c2;
}

.low-priority-text {
  color: #c789c2;
}

.demo {
  width: 50%;
}

.task_management_status_card_base {
  background-color: #f9fcee !important;

  @media screen and (max-width: 767px) {
    padding: 16px !important;
    width: 100%;
  }

  .alert_text_wrapper {
    @include flexbox-properties(flex, row, flex-start, center, 6px);
  }

  .card_main_heading {
    @include font-properties(16px, 600, #587e85);
    text-decoration: none;
    line-height: 18px;
    word-break: break-word;
  }

  .date {
    @include font-properties(12px, 400, #6f7788);
  }

  .comment_date_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 0);
    .comment_text {
      @include font-properties(12px, 400, #6f7788);
    }
  }

  .card_top_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 0);
    margin-bottom: 16px;
    margin-top: 8px;

    @media screen and (max-width: 767px) {
      @include flexbox-properties(flex, row, space-between, flex-start, 8px);
    }

    .status_priority_date_wrapper {
      @include flexbox-properties(flex, row, flex-start, center, 8px);
      @media screen and (max-width: 767px) {
        @include flexbox-properties(flex, column, flex-start, flex-start, 8px);
      }

      .status_priority {
        @include flexbox-properties(flex, row, flex-start, center, 8px);
      }
    }
    .custom_dropdown_wrapper {
      padding: 8px 20px;
      border: none;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
      border-radius: 10px;

      .dropdown_list {
        border-bottom: 1px solid #e0e0e0;
        padding-top: 12px !important;
        padding-bottom: 12px !important;

        &:active {
          background-color: $white !important;
        }

        &:last-child {
          border: none;
        }
        .dropdown_link {
          text-decoration: none;
          @include font-properties(13px, 400, #2d3245);
          margin: 0;
          &:hover {
            color: #a9cf3d;
          }
        }
      }
    }
  }

  .office_assignby_wrapper {
    @include flexbox-properties(flex, row, space-between, flex-start, 20px);
    margin-top: 16px;

    .link_btn {
      @include font-properties(14px, 600, #587e85);
      text-decoration: underline;
    }

    .office_name {
      max-width: 105px;
    }
  }
}

.task_management_status_card_not_active {
  @extend .task_management_status_card_base;
  width: 47.5%;

  @media (max-width: 1100px) {
    width: 100%;
  }

  @media (min-width: 1101px) and (max-width: 1440px) {
    width: 47%;
  }
}

.task_management_status_card_active {
  @extend .task_management_status_card_base;
  width: 31%;

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1166px) {
    width: 48%;
  }
}

.task_management_card_grid {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding-left: 46px;
  height: 100%;

  @media screen and (max-width: 991px) {
    padding-left: 16px;
    gap: 20px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 0;
    gap: 20px;
  }
}

.task_details_card {
  margin-top: 30px;
  .task_detail {
    display: flex;
    flex-direction: row;
    padding: 54px;

    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      padding: 4px;
    }

    .left_section {
      width: 50%;
      border-right: 1px solid #dadce0;
      padding-right: 70px;

      @media screen and (max-width: 991px) {
        border: none;
        width: 100%;
        padding-right: 0;
      }

      .overdue_warning {
        @include font-properties(11px, 400, #ee4f4f);
        line-height: 16.5px;
      }
      .status_priority_date_wrapper {
        @include flexbox-properties(flex, row, flex-start, center, 8px);
        margin-top: 30px;

        @media screen and (max-width: 991px) {
          margin-top: 10px;
          @include flexbox-properties(
            flex,
            column,
            flex-start,
            flex-start,
            8px
          );
        }

        .priority_status {
          @include flexbox-properties(flex, row, flex-start, center, 8px);
          @media screen and (max-width: 767px) {
            @include flexbox-properties(flex, row, flex-start, center, 10px);
          }
        }
        .date {
          @include font-properties(13px, 400, #6f7788);
        }
      }

      .office_assignby_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin-top: 30px;
      }

      .office_name {
        display: flex;
        flex-direction: column;
      }
      .description {
        margin-top: 30px;
      }
      .toggle_btn_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-top: 1px solid #e0e0e0;
        padding-top: 22px;
        padding-bottom: 30px;
        margin-top: 30px;

        @media screen and (max-width: 991px) {
          border-bottom: 1px solid #e0e0e0;
        }
      }
    }

    .right_section {
      width: 55%;
      padding-left: 70px;

      @media screen and (max-width: 991px) {
        width: 100%;
        padding-left: 0;
        margin-top: 30px;
      }
      .dropdown_wrapper {
        @include flexbox-properties(flex, row, flex-start, center, 20px);
        margin-top: 30px;
      }
      // styles.module.scss

      .task-list-container {
        max-height: 522px;
        overflow-y: auto;
        margin-top: 30px;
        @include flexbox-properties(flex, column, flex-start, center, 10px);

        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #e0e0e0;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #587e85;
          border-radius: 10px;
        }
      }
    }
  }
}
.reason_for_rejection {
  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
}
.task_details_card_wrapper {
  background-color: #f9fcee !important;
  width: 100%;
  @extend .reason_for_rejection;

  .overdue_warning {
    @include font-properties(11px, 400, #ee4f4f);
    line-height: 16.5px;
  }
  .btn_dropdown_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 20px);
    margin-top: 8px;
    margin-bottom: 8px;
    .custom_dropdown_wrapper {
      padding: 8px 20px;
      border: none;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
      border-radius: 10px;

      .dropdown_list {
        border-bottom: 1px solid #e0e0e0;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        &:active {
          background-color: $white !important;
        }
        &:last-child {
          border: none;
        }
        .dropdown_link {
          text-decoration: none;
          margin: 0;
          @include font-properties(13px, 400, #2d3245);
          &:hover {
            color: #a9cf3d;
          }
        }
      }
    }
  }
  .card_main_heading {
    @include font-properties(16px, 600, #587e85);
    text-decoration: none;
    line-height: 20px;
    margin-top: 16px;
    word-break: break-word;
  }
  .dental_name_comment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 4px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }
  }
}

.patient_name_image_btn_wrapper {
  @include flexbox-properties(flex, row, space-between, center, 20px);
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  padding-top: 16px;

  .patient_image_name_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 16px);

    .popup_patient_image {
      height: 40px;
      width: 40px;
      border-radius: 12px;
    }
  }
}

.delete_task_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .delete_task_btn_wrapper {
    margin-top: 40px;
    width: 75%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .delete_task_btn {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.add_new_note_card_wrapper {
  padding: 70px !important;
  @media screen and (max-width: 767px) {
    padding: 16px !important;
  }
  .add_new_note_parent_wrapper {
    width: 40%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 60%;
    }
    .date_wrapper {
      @include flexbox-properties(flex, row, space-between, center, 20px);

      @media screen and (max-width: 767px) {
        @include flexbox-properties(flex, column, flex-start, flex-start, 20px);
        width: 100%;
      }

      .new_inputdate {
        width: 100% !important;
      }
    }
    .assiness_input {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 15px;
      margin-top: 30px;
    }
    .btn_wrapper {
      margin-top: 40px;
    }
    .checkbox_wrapper_one {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.checkbox_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;

  .check_box_width {
    min-width: 20px;
  }
  .checkbox_height {
    min-height: 20px;
    margin-top: 4px;
    padding-left: 20px;
  }
  .name_office {
    display: flex;
    flex-direction: column;
    .office_name {
      word-break: break-word;
    }
  }
  .popup_patient_image {
    height: 40px;
    width: 40px;
    border-radius: 12px;
  }
}

.task_performance_card {
  padding: 30px !important;
  background-color: #f9fcee !important;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 16px;

    .column {
      max-width: 111px;
      width: 100%;
    }
  }
}

.task_performance_wrapper {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  .card_padding {
    @media screen and (min-width: 1880px) {
      padding: 72px 40px !important;
    }
    @media screen and (min-width: 1440px) {
      padding: 72px 50px !important;
    }
    @media screen and (min-width: 1280px) {
      padding: 72px 52px !important;
    }
    @media screen and (min-width: 991px) {
      padding: 10px 40px !important;
    }
  }
}
.dropdown_date_wrapper {
  @include flexbox-properties(flex, row, space-between, center, 20px);
  @media screen and (max-width: 767px) {
    @include flexbox-properties(flex, column, space-between, flex-start, 20px);
  }
  .date_wrapper {
    @include flexbox-properties(flex, row, flex-start, center, 20px);
  }
}

.performance_detail_card {
  padding: 30px !important;
  @media screen and (max-width: 767px) {
    padding: 20px !important;
  }
  .patient_status_priority_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 20px);
    @media screen and (max-width: 767px) {
      @include flexbox-properties(
        flex,
        column-reverse,
        space-between,
        flex-start,
        20px
      );
    }
    .status_priority_wrapper {
      @include flexbox-properties(flex, row, flex-start, center, 10px);
    }
  }
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 16px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .column {
    text-align: start;
  }
}

.modal_scroll {
  height: 221px;
  overflow-y: scroll;
  padding-right: 8px;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e0e0e0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #587e85;
    border-radius: 10px;
  }
}
.custom_modal_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .modal_heading {
    font-size: 25px;
    line-height: 25px;
    color: #111b45;
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 25px;
      font-weight: 500;
      margin-top: 0;
    }
  }
  .modal_data {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #535b5f;
    max-width: 485px;
    margin-top: 4px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
  .btn_container {
    margin-top: 40px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;

    @media screen and (max-width: 767px) {
      width: 100%;
      flex-direction: column;
      gap: 1px;
    }

    .green_btn {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .white_btn {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.heading-data-Wrapper {
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  margin-top: 30px;

  .file_upload_wrapper {
    @include flexbox-properties(flex, row, space-between, center, 0);
    margin-bottom: 16px;
    margin-top: 8px;
  }

  .trash_image {
    height: 18px;
    width: 18px;
  }

  @media screen and (max-width: 767px) {
    width: 100% !important;
    display: block;
  }

  .head-link-wrapper {
    @include flexbox-properties(flex, row, space-between, flex-start, 0);
    .appointment-head {
      @include font-properties(13px, 400, #79869a);
    }

    .appointment-link {
      @include font-properties(12px, 500, $teal);
      border: none;
      text-decoration: underline;
      background-color: white;
    }
  }

  .appointment-text {
    @include font-properties(14px, 600, $dark-blue);
  }
}

.not_found {
  @include flexbox-properties(flex, row, center, center, 0);
  width: 100%;
}
.no_border {
  border: none;
}

.new_search_bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 7px 10px;

  @media (max-width: 1166px) {
    margin-top: 20px;
    max-width: 100%;
  }
}

.search_input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 10px;
  max-width: calc(100% - 20px);
}

.search_input::placeholder {
  color: #979d9f;
}

.search_icon {
  @include flexbox-properties(flex, row, center, center, 20px);
  padding: 5px;
}

.search_icon img {
  min-width: 16px;
  height: 16px;
}
.task_management_main_card {
  padding: 70px !important;

  @media screen and (max-width: 991px) {
    padding: 10px 15px 20px !important;
    border-radius: 10px !important;
  }
}

.assignes_staff_scroll {
  height: 345px;
  overflow-y: scroll;
  padding-right: 8px;
  margin-top: 30px;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e0e0e0;
  }
  &::-webkit-scrollbar-thumb {
    background: #587e85;
    border-radius: 10px;
  }
}
.break_word {
  word-break: break-word;
}
.staff_right_section {
  padding-left: 70px;
  width: 50%;

  @media screen and (max-width: 991px) {
    padding-left: 0;
    width: 100%;
  }
  .comment_container {
    width: 100%;
    .comment_topbar {
      background-color: $cultured-Pearl;
      padding: 17px 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid $border-gray;
    }
    .comment_body {
      background-color: #f2f3ef;
      height: 400px;
      border: 1px solid $border-gray;
      padding: 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #e0e0e0;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #587e85;
        border-radius: 10px;
      }
      .comment_text {
        background-color: $teal;
        padding: 20px;
        border-radius: 10px;
        p {
          margin: 0;
        }
        .date_time_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 4px;
        }
        .patient_name {
          font-size: 10px;
          line-height: 12px;
          font-weight: 600;
          color: $parrot-green;
        }
        .date_time {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          color: white;
          margin-top: 12px;
          word-break: break-word;
        }
      }
      .my_comment {
        background-color: $white;
        p {
          margin: 0;
        }
        .patient_name {
          color: #587e85;
        }
        .date_time {
          color: #2f3245;
        }
      }
    }
    .comment_footer {
      background-color: $cultured-Pearl;
      padding: 11px 20px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid $border-gray;
      width: 100%;
      display: flex;
      justify-content: space-between;
      input {
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #2f3245;
        line-height: 13px;
        border: 1px solid #dadada;
        width: 80%;
        border-radius: 5px;

        &::placeholder {
          font-size: 14px;
          font-weight: 500;
          color: #d5d8d9;
        }
      }
      img {
        background-color: $parrot-green;
        border-radius: 50%;
        padding: 8.7px;
      }
    }
  }
}
.sorting_filter_width {
  width: 78px !important;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    width: 100% !important;
    padding-top: 16px;
  }
}
.operator_radio_btn {
  display: flex;
  flex-direction: column;
}

.operator_radio_btn > * {
  padding-bottom: 14px !important;
  padding-top: 14px !important;
  border-bottom: 1px solid #ccc;
}
.name_office_cont {
  display: flex;
  flex-direction: column;
}
.employe_modal_boder {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
