.hour-rate-table {
  tbody {
    @media screen and (max-width: 991px) {
      td:last-child {
        text-align: left !important;
      }
    }
  }
}

.subtitle_text {
  font-size: 13px;
  font-weight: 400;
  color: #79869a;
  margin: 20px 0 0 0;
  text-transform: capitalize;
}
.title_content_text {
  font-size: 16px;
  font-weight: 600;
  color: #102c42;
  margin: 0 0 20px 0;
  text-transform: capitalize;
  word-break: break-word;
}
