.invoices_nav_tabs_wrapper {
  min-height: fit-content;

  .invoices_nav_tabs {
    display: flex;
    justify-content: flex-start;
    gap: 60px;

    @media screen and (max-width: 767px) {
      gap: 24px;
      justify-content: center;
    }

    .invoices_nav_item {
      max-width: fit-content;
      width: fit-content;

      a {
        background-color: transparent;
        padding: 6px 0 14px;
      }
    }
  }
}

