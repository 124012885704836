.delete_event_card {
  padding: 70px !important;

  .content_wrapper {
    width: 100%;
    max-width: 420px;

    .delete_radio_btn_wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .link_btn_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: solid 1px rgba($color: #979797, $alpha: 0.4);
      padding-bottom: 12px;
      margin-top: 30px;

      .text_wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}

.calender_styles {
  background-color: white;
  box-shadow: 0px 0px 15px 0px #00000015;
  border-radius: 10px;
  width: fit-content;
  padding: 10px;
  margin-top: 6px;

  :global {
    .scheduler-calendar {
      .react-calendar__month-view__days__day {
        abbr {
          color: #333; // Default text color
        }

        &.react-calendar__tile--active,
        &.react-calendar__tile--hasActive {
          abbr {
            color: #ffffff !important; // White text for selected dates
          }

          &:hover abbr {
            color: #ffffff !important; // Maintain white text on hover
          }
        }

        &:disabled abbr {
          color: #ccc; // Disabled date text color
        }
      }
    }

    .react-calendar {
      width: 100%;
      border: none;
      background: white;
      font-family: inherit;
      line-height: 1.125em;

      // Navigation section (month/year controls)
      &__navigation {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 12px;

        button {
          min-width: 36px;
          background: none;
          border: none;
          padding: 8px;
          border-radius: 6px;
          cursor: pointer;

          &:hover {
            background-color: #f5f5f5;
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        &__label {
          font-weight: 500;
          font-size: 16px;
          flex-grow: 1;
          text-align: center;
        }
      }

      // Weekday headers
      &__month-view__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        color: #666;
        padding: 4px 0;

        abbr {
          text-decoration: none;
          border: none;
        }
      }

      // Calendar tiles (days)
      &__tile {
        padding: 12px;
        text-align: center;
        border-radius: 50%; // Make tiles circular
        font-size: 14px;
        position: relative;
        background: none;
        border: none;
        outline: none;
        transition: all 0.2s ease;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: #333; // Default text color

        &:enabled {
          cursor: pointer;

          &:hover {
            background-color: #f0f0f0;
          }
        }

        &:disabled {
          background-color: transparent;
          color: #ccc;
          cursor: not-allowed;
        }

        // Today's date
        &--now {
          font-weight: 500;
        }

        // Selected date
        &--active,
        &--hasActive {
          background-color: #a9cf3d !important;
          color: #ffffff !important; // Ensure text is white when selected

          &:hover {
            background-color: #98bb35 !important;
            color: #ffffff !important; // Maintain white text on hover
          }
        }
      }

      &:enabled {
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }
      }

      &:disabled {
        background-color: transparent;
        color: #ccc;
        cursor: not-allowed;
      }

      // Today's date
      &--now {
        color: inherit;
        font-weight: 500;

        &::after {
          content: "";
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #666;
        }
      }

      // Selected date
      &--active,
      &--hasActive {
        background-color: #a9cf3d !important;
        color: white !important;

        &:hover {
          background-color: #98bb35 !important;
        }

        &::after {
          display: none; // Remove the dot for selected dates
        }
      }
    }

    // Month view specific styles
    &__month-view {
      padding: 8px;

      &__days__day {
        &--weekend {
          color: inherit;
        }
      }
    }
  }
}

.calendar_tile {
  &.selected {
    background-color: #a9cf3d !important;
    color: #ffffff !important;

    &:hover {
      background-color: #98bb35 !important;
      color: #ffffff !important;
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .delete_event_card {
    padding: 24px !important;

    .content_wrapper {
      max-width: 100%;
    }
  }

  .calender_styles {
    width: 100%;
    padding: 8px;

    :global {
      .react-calendar {
        &__tile {
          padding: 8px;
          font-size: 13px;
        }

        &__navigation {
          padding: 0 8px;
          margin-bottom: 12px;

          button {
            min-width: 32px;
            padding: 6px;
          }
        }
      }
    }
  }
}
