.mfa_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 20px);
  max-height: 690px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
    padding: 16px;
    overflow-y: scroll;
    max-height: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
  .mfa_left_side {
    width: 50%;
    text-align: center;
    background: #587e85;
    min-height: 690px;
    border-radius: 25px 0 0 25px;
    position: relative;

    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 100%;
      border-radius: 25px 25px 0 0;
    }

    @media screen and(max-width:767px) {
      display: none;
    }
    .logo {
      padding-left: 0px;
      padding-top: 83px;
    }
    .signup_block_link {
      color: #fff;
      margin-top: 50px;
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  .mfa_right_side {
    width: 50%;
    background-color: #fff;
    border-radius: 0px 25px 25px 0px;
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 100%;
      border-radius: 0 0 25px 25px;
      padding: 50px;
    }
    @media screen and(max-width:767px) {
      width: 100%;
      border-radius: 25px;
      padding: 30px 20px;
    }

    .field_group {
      padding: 70px 92px 0;
      @media screen and(max-width:991px) {
        padding: 0;
      }
      .title {
        font-size: 25px;
        line-height: 35px;
        font-weight: 700;
        color: #2d3245;
        margin-bottom: 16px;
        margin-top: 60px;
        @media screen and(max-width:767px) {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin-top: 24px;
        }
      }
      .sub_title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #111b45;
        @media screen and(max-width:767px) {
          font-size: 12px;
          line-height: 18px;
          color: #87928d;
          margin: 0;
        }
      }
      .buttons {
        margin-top: 50px;
        @media screen and(max-width:767px) {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        button {
          text-align: left;
          min-height: 52px;
          line-height: 52px;
          color: #ffff;
          font-weight: 600;
          font-size: 15px;
          border-radius: 26px;
          box-shadow: 4px 5px 14px 0 #e4e9dd;
          background-color: #a9cf3d;
          cursor: pointer;
          border: 0;
          position: relative;
          width: 160px;
          padding-left: 20px;
          @media screen and(max-width:767px) {
            width: 100%;
            text-align: center;
            padding-left: 0;
          }
          &:after {
            content: "";
            position: absolute;
            right: 0;
            background: #9dc134
              url("https://mxhhstagingstorageacc.blob.core.windows.net/auth/lock.svg")
              no-repeat center;
            width: 20px;
            bottom: 0;
            height: 100%;
            border-radius: 0 26px 26px 0;
            width: 50px;
            background-position: center left 12px;
            @media screen and(max-width:767px) {
              display: none;
            }
          }
        }
      }
      .verification_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn_time {
          display: flex;
          align-items: center;
          gap: 10px;
          .resend_btn {
            outline: none;
            border: none;
            background-color: white;
          }
        }
        .verification_text {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          letter-spacing: -0.2px;
          color: #2d3245;
          margin: 0;
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}
