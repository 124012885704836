$Teal: #587e85;
$HeadingColor: #111b45;
$white: #ffffff;
$blueColor: #66accf;
$redColor: #ee4f4f;
$greenColor: #a9cf3d;
$lightGray: #6f7788;
$darkNavyBlue: #102c42;
$PrimaryText: #587e85;
$lightGreen: #f9fcee;
$LightGray: #dbdbdb;

@mixin buttonDesign(
  $textColor,
  $textSize,
  $textWeight,
  $textPadding,
  $backgroundColor,
  $borderRadius,
  $border
) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
  padding: $textPadding;
  background-color: $backgroundColor;
  border-radius: $borderRadius;
  border: $border;
}

@mixin textDesign($textColor, $textSize, $textWeight) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
}

.first-row-top {
  .first-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
    .first-row-text-part {
      width: 30%;
      @media screen and (max-width: 992px) {
        width: unset;
        order: 1;
      }
    }

    .first-row-left-part {
      .change-date-box {
        display: flex;
        gap: 16px;
        position: relative;
        .open-datepicker-box {
          position: absolute;
          top: 50%;
          opacity: 0;
        }

        .change-date {
          color: #587e85;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          text-decoration-line: underline;
          cursor: pointer;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 2px;
        }
      }
    }

    .patient-page-heading {
      @include textDesign($HeadingColor, 25px, 500);
    }

    .yellow-warning-box {
      padding: 10px 20px;
      background-color: #fcc539;
      border-radius: 6px;
      align-items: center;
      display: none;
      gap: 10px;
      justify-content: space-between;
      margin-top: 20px;

      @media screen and (max-width: 992px) {
        width: 100%;
        align-items: flex-start;
        order: 2;
        margin-top: 0;
      }
    }
  }
}
.btn-box {
  display: flex;
  gap: 20px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  @media screen and (max-width: 992px) {
    margin-top: 20px;
    order: 3;
  }
}
.reject-yellow-warning-box {
  padding: 10px 20px;
  background-color: #fcc539;
}

.appointment-tabs {
  background-color: $white;
  padding: 9px 70px 30px 70px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  .nav-tabs {
    margin-bottom: 45px;
    border: none;

    .nav-items {
      width: 33.33%;
      text-align: center;
      color: $LightGray;
      cursor: pointer;
      @include textDesign(#dbdbdb, 20px, 500);
      .active {
        border: none;
        line-height: 25px;
        @include textDesign($HeadingColor, 20px, 500);
        border-bottom: 2px solid $greenColor;

        @media screen and (max-width: 576px) {
          @include textDesign($HeadingColor, 13px, 500);
        }
      }

      .not-active {
        border: none;
      }
      @media screen and (max-width: 576px) {
        @include textDesign(#dbdbdb, 13px, 500);
      }
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 20px;
    @include textDesign($HeadingColor, 13px, 500);
  }
}

.details-heading {
  line-height: 12px;
  margin-bottom: 10px;
  @include textDesign($lightGray, 12px, 400);
}

.details-data {
  line-height: 14px;
  margin-bottom: 30px;
  @include textDesign($darkNavyBlue, 14px, 600);
}

.chat-icon-box {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 100px;
  background-color: #f2f3ef;
  img {
    cursor: pointer;
  }
}

.View-submitted-question {
  text-decoration-line: underline;
  @include textDesign(#587e85, 12px, 500);
}

.search-results {
  width: 50%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .add-notes-box {
    width: 100%;
  }
  .patient-appointment-type-input-box {
    margin-bottom: 20px;
  }

  .illness-type {
    border: none;
    border-bottom: 1px solid lightgray;
    width: 100%;
    margin-bottom: 20px;
  }

  .illness-type-btn-container {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
  }
}

.illness-type-save-cancel-btn {
  border: none;
  background-color: white;
  text-decoration: underline;
  @include textDesign(#587e85, 12px, 500);
}

.add-new-member-btn {
  display: flex;
  margin-top: 40px;
}

.common-appointment-btn-container {
  margin-top: 40px;
}
.common-appointment-center-btn-container {
  margin-top: 30px;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
}

.notes-container {
  max-width: 680px;
  border-top: 1px solid #e0e0e0;
  padding: 30px 0px;
}

.appointment-history-tab-modal-btn {
  @include buttonDesign(#587e85, 14px, 500, 0px 0px, white, 100px, none);
  text-decoration: underline;
  display: flex;
  flex-direction: column;
}
.history-table-status-columns {
  @include textDesign(#535b5f, 14px, 400);
}
.history-table-patientName-columns {
  @include textDesign(#535b5f, 14px, 400);
}

.appointment-table-row-main-link {
  text-decoration: underline;
  @include textDesign(#587e85, 14px, 500);

  .appointment-table-patient-name {
    text-decoration: underline;
    cursor: pointer;
    white-space: pre-line;
    min-width: 100px !important;
    margin: 0;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
}

.patient-appointment-table-common-columns {
  @include textDesign(#535b5f, 14px, 400);
  line-height: 22px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}

.covid-form-question {
  margin-top: 30px;

  &:first-child {
    margin-top: 0px !important;
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }
}

.appointment-history-modal-scrollbar {
  height: 400px;
  overflow: auto;
}

.appointment-modal-cross-btn {
  border: none;
  border-radius: 50px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.patient-appointment-type {
  padding: 70px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    padding: 30px;
  }

  .HygieneClassWrapper {
    width: 50%;

    .HygieneClass {
      border-bottom: 1px solid lightgray;
      margin-bottom: 15px;
      padding-bottom: 15px;
      margin-top: 20px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.submitted-questionnaire-card {
  width: 100%;
  padding: 30px;
  margin: 10px auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.083998);
  border-radius: 10px;
  background-color: white;

  .sub-option {
    padding: 10px 20px;
    background: #f9fcee;

    .sub-option-radio {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.patient-appointment-detail-container {
  display: flex;
  gap: 70px;
  @media screen and (max-width: 768px) {
    gap: 20px;
  }

  .patient-appointment-detail-left {
    border-right: 1px solid lightgray;
    width: 35%;
    padding-right: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
      border-bottom: 1px solid lightgray;
      border-right: none;
      width: 100%;
      padding-bottom: 20px;
      padding-right: 0;
    }
    .profile-image-container {
      width: 180px;
      @media screen and (max-width: 767px) {
        width: 80px;
      }
      .profile-pic {
        max-width: 180px;
        max-height: 180px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        @media screen and (max-width: 767px) {
          max-width: 80px;
          max-height: 80px;
        }
      }
    }

    .patient-name {
      @include textDesign(#587e85, 18px, 600);
      text-align: left;
      margin-bottom: 40px;
      margin-top: 12px;
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    .profile-btn-box {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }

  .patient-appointment-detail-right {
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .patient-appointment-detail-right-card {
      padding: 20px;
      background-color: #f9fcee;
      border: none;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .patient-appointment-detail-right-card-data {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          width: 100%;

          .patient-appointment-detail-right-card-heading:nth-child(3) {
            margin-bottom: 25px;
            order: -1;
          }
        }

        .patient-appointment-detail-right-card-heading {
          @media screen and (max-width: 767px) {
            &:nth-child(2) {
              margin-top: 25px;
            }
          }
        }

        .patient-appointment-detail-right-card-data-pending-btn {
          @include buttonDesign(#ffffff, 10px, 500, 4px, #66accf, 6px, none);
        }
      }

      .patient-appointment-detail-right-card-data-reschedule-reassign-btn-container {
        display: flex;
        gap: 20px;

        .patient-appointment-detail-right-card-data-reschedule-reassign-btn {
          margin-top: 30px;
          text-decoration: none;
          @include buttonDesign(
            #587e85,
            15px,
            600,
            7px 25px,
            #f9fcee,
            100px,
            2px solid #587e85
          );
        }
      }
    }

    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .not-active,
      .active,
      .nav-links {
        &:first-child {
          padding-left: 0px;
          padding-right: 60px;
        }
      }

      .nav-link::before {
        left: 24%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.select-appointment-reminder-dropdown {
  width: 43%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  .custom-dropdown-only {
    margin-bottom: 10px;
  }
}

.select-appointment-reminder-plan {
  width: 43%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  .appointment-reminder-modal-card-Wrapper {
    margin-top: 40px;
  }
}

.heading-data-Wrapper {
  border-bottom: 1px solid lightgray;
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    width: 100% !important;
    display: block;
  }

  .head-link-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .appointment-head {
      @include textDesign(#79869a, 13px, 400);
    }

    .appointment-link {
      @include textDesign(#587e85, 12px, 500);
      border: none;
      text-decoration: underline;
      background-color: white;
      cursor: pointer;
    }
  }

  .appointment-text {
    margin-bottom: 15px;
    @include textDesign(#102c42, 14px, 600);
  }
}

.patientAppointmentDetails-left-part {
  max-width: 420px;
  width: 100%;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }

  .appointment-input-box {
    margin-top: 30px;
    width: 50%;
    background-color: #f9fcee;
  }
}

.PatientAppointmentDetails-cross-btn {
  border: none;
  border-radius: 50px;
  position: absolute;
  right: 30px;
  top: 20px;
}

.PatientAppointmentDetailsInputBox {
  margin-top: 30px;
}

.patient-appointment-details-modalbody {
  text-align: center;
}

.appointment-modal-card {
  background-color: #f9fcee;
  padding: 20px;
  border: none;
  margin-bottom: 10px;

  input,
  .appointment-input-box {
    background-color: #f9fcee;
  }

  .appointment-modal-data {
    .appointment-modal-head {
      @include textDesign(#79869a, 13px, 400);
    }

    .appointment-date-dropdown-wrapper,
    .delete-icon-text-wrapper,
    .appointment-checkboxes {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 30px;
    }
    .appointment-checkboxes {
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .appointment-modal-para,
    .select_menu {
      border: none;
      border-bottom: 1px solid lightgray;
      background-color: #f9fcee;
      @include textDesign(#102c42, 14px, 600);
    }

    .custom-dropdown-wrapper {
      margin-top: 30px;
    }
  }
}
.urban-dental-card {
  border: none;
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
  padding: 30px;
  height: 100%;
  border-radius: 12px;

  .urban-dental-logo {
    img {
      border-radius: 15px;
      max-width: 100px;
      max-height: 100px;
      min-width: 100px;
      min-height: 100px;
    }
  }

  .urban-dental-text {
    @include textDesign($PrimaryText, 16px, 600);
  }
}

.main-page {
  h3 {
    @include textDesign($HeadingColor, 25px, 500);
  }

  .manage-schedule-heading {
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    }

    .select-office {
      @include textDesign(#000000, 14px, 300);
      margin-bottom: 30px;
    }
  }

  .select-input-type {
    margin-bottom: 30px;

    .select-input-type-text {
      border-bottom: 1px solid #c4c4c3;
      padding-bottom: 15px;
    }
  }

  .manage-card-container {
    flex-wrap: wrap;
    gap: 30px;
    @media screen and (max-width: 768px) {
      gap: 10px;
    }
  }

  .card-con {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    a {
      text-decoration: none;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .content-part {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .scheduler-calendar-timezone-separator {
      border-bottom: 1px solid #e1e8da;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    .show-date {
      margin-bottom: 32px;

      .custom-show-date {
        border: none;
        color: #111b45;
        font-weight: 600;
        font-size: 16px;
      }

      .change-date-text {
        text-decoration-line: underline;
      }
    }

    .recall-date-box {
      max-width: 420px;
      gap: 40px;
      cursor: pointer;
    }

    .book-slot {
      padding: 30px;
      background-color: $lightGreen;
      gap: 16px;
      @media screen and (max-width: 767px) {
        padding: 20px;
      }

      .show-time-box {
        gap: 10px;
        .common-time-box {
          width: 61px;
          border-radius: 6px;
          border: 1px solid #e5e7df;
          line-height: 10px;
          text-align: center;
          padding: 8px 5px;
          cursor: pointer;
          @include textDesign(#bbbdb5, 10px, 500);
        }

        .blocked-time-box {
          background-color: #e5e7df;
          border: none;
        }

        .booked-time-box {
          background-color: #a9cf3d;
          color: #ffffff;
          border: none;
        }
      }
    }

    .btn-container {
      display: flex;
      gap: 25px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }

  .patient-detail-box {
    max-width: 420px;

    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 100%;
    }

    .new-appointment-input-box {
      margin-top: 30px;
    }
  }

  .modal-btn-container {
    display: flex;
    gap: 25px;
  }

  .track-schedule {
    padding: 30px;
    margin-bottom: 10px;
    .schedule-update {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        flex-direction: column-reverse;
      }
      .schedule-update-text {
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
          margin-top: 20px;
        }
      }
    }
    .track-schedul-row {
      display: flex;
      flex-wrap: wrap;
      .track-schedul-col {
        width: 25%;
        margin-top: 30px;
        @media screen and (max-width: 768px) {
          width: 50%;
        }
      }
    }
    .custom-schedule-btn {
      padding: 4px 10px;
      background-color: #66accf;
      border-radius: 6px;
      border: none;
    }
  }

  .recall-card {
    gap: 70px;

    .left-recall-part {
      width: 250px;
      border-right: 1px solid #e0e0e0;
      gap: 30px;
    }

    .right-recall-part {
      width: 420px;

      .btn-container {
        display: flex;
        gap: 25px;
      }
    }
  }

  .date-btn-container {
    gap: 25px;
  }

  .date-picker-container {
    gap: 20px;
    width: 340px;
  }
}

.timeslot-modal {
  h3 {
    @include textDesign($HeadingColor, 25px, 500);
  }

  padding: 30px;

  .modal-content-part {
    gap: 39px;

    .modal-btn-container {
      display: flex;
      gap: 25px;
    }
  }
}

.reassign-appointment-left-part {
  min-width: 290px;
}

.urban-dental-reassign-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  width: 100%;

  .show-time-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
    gap: 10px;
    place-items: center;

    @media screen and (max-width: 767px) {
      max-height: 200px;
      overflow-y: scroll;
      padding-right: 6px;
    }
  }
}

.common-time-box {
  width: 63.5px;
  border-radius: 6px;
  border: 1px solid #e5e7df;
  line-height: 12px;
  text-align: center;
  padding: 7px 5px;
  cursor: pointer;
  background: var(--slot-available, #fff);
  @include textDesign(#bbbdb5, 11px, 500);
}

.see-todays-schedule-btn-wrapper {
  display: flex;
  gap: 25px;
  text-decoration: none;

  .manage-schedule {
    @include buttonDesign(
      #587e85,
      15px,
      600,
      13.8px 25px,
      #f2f3ef,
      100px,
      2px solid #587e85
    );
  }

  button {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  @media screen and (max-width: 992px) {
    margin-top: 20px;
  }
}

.create-new-appointment-wrapper {
  display: flex;
  gap: 30px;

  .create-patient-appointment-detail-box {
    min-width: 313px;
  }

  .create-patient-appointment-right-part {
    padding: 30px;
    border-radius: 6px;
    background: #f9fcee;
    border: none;
  }
}

.create-new-appointment-left-part {
  min-width: 313px;
  @media screen and (max-width: 768px) {
    min-width: unset;
  }
}

.new-appointment-input-box {
  margin-top: 30px;
}

.book-slot-heading {
  border-bottom: 2px solid #e4ecd4;
  padding-bottom: 15px !important;
}

.notify-checkbox {
  gap: 42px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }

  .checkbox-container {
    align-items: center;

    .show-checkedbox {
      width: 16px;
      height: 16px;
      border-radius: 3px;
      margin-right: 7px;
    }

    .box1 {
      background-color: #a9cf3d;
    }

    .box2 {
      background-color: #ffffff;
      border: 1px solid #e5e7df;
    }

    .box3 {
      background-color: #e5e7df;
    }

    @include textDesign(#2f3245, 10px, 500);
  }
}

.recall-patient-appointment-details-wrapper {
  display: flex;
  gap: 30px;

  @media screen and (max-width: 991px) {
    display: grid;
  }

  .recall-patientAppointmentDetails-left-part {
    min-width: 340px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      min-width: unset;
      padding: 0;
    }
  }

  .recall-patientAppointmentDetails-right-part-card {
    width: 100%;
    padding: 30px;
    border-radius: 6px;
    background: #f9fcee;
    border: none;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding: 16px;
    }
  }
}

.book-slot {
  .custom-appointment-scheduler-calendar {
    margin: 0px !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    @media screen and (max-width: 768px) {
      border-radius: 10px;
    }
  }

  .appointment-calendar-timezone-separator {
    border-bottom: 1px solid #e1e8da;
  }
}

.date-picker-container {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  padding-right: 30px;
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
    padding-right: 0;
  }
}

.appointment-table-box {
  width: 100%;
  height: 100%;
  .appointment-search-box {
    border-radius: 5px;
    border: 1px solid #dadada;
    background: #fff;

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}
.appointment-table-box {
  height: 100%;
  .appointment_list_table th {
    background-color: white !important;
    border: none !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.office-dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  border: none;
  border-radius: 10px;

  .office-dropdown-item {
    border-bottom: 1px solid #d8d8d8;
    font-size: 13px;
    font-weight: 400;
    padding: 12px 0;
    background-color: transparent;
    color: #111b45;
    white-space: nowrap;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }

    &:first-child {
      padding-top: 0px;
    }
    .office-dropdown-link {
      text-decoration: none;
    }
  }
}

.table-status-box {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  .red-alert-box {
    .red-alert-icon {
      min-width: 17px;
      margin-left: 10px;
    }
  }
}

.patient-appointment-detail-right-card-data-cancel-btn {
  @include buttonDesign(#ffffff, 10px, 500, 4px, #ee4f4f, 6px, none);
}

.patient-appointment-detail-right-card-data-completed-btn {
  @include buttonDesign(#ffffff, 10px, 500, 4px, $greenColor, 6px, none);
}

.appointment-link {
  @include textDesign(#587e85, 12px, 500);
  border: none;
  text-decoration: underline;
  background-color: white;
  cursor: pointer;
}

.patient-appointment-detail-modal-card {
  border: none;
  background-color: #f9fcee;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 40px;
}

.recall_top_card_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.patient_appointment_details_upper_card_data {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.btn-container-block-appointment {
  @media screen and (max-width: 767px) {
    border: none;
    text-decoration: underline;
  }
}

.book-slot-new-appointment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
}
.reminder-plan-modal {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.patient-name {
  white-space: nowrap;
}

.mobile-text-size {
  font-size: 25px;
  font-weight: 500;
  color: #111b45;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    font-weight: 500;
  }
}
.book-timeslot-container {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  overflow-y: hidden;
  overflow-y: scroll;
  max-height: 60vh;
}
.details_card {
  padding: 70px !important;
  display: flex;
  flex-direction: row;
  gap: 70px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 30px;
    padding: 20px !important;
  }
  .waiting_list_details_left_section {
    border-right: 1px solid #e0e0e0;
    min-width: 290px;
    padding-right: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 991px) {
      margin-right: 0;
      border: none;
      padding: 0;
    }
    .patient_image {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .waiting_list_details_right_section {
    width: 100%;
    background-color: #f9fcee;
    padding: 20px;
    .title_btn_wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }
}
.appointment-tabs-new {
  background-color: $white;
  overflow: scroll;
  width: 100%;
  .nav-tabs {
    margin-bottom: 45px;
    border: none;

    .nav-items {
      width: 33.33%;
      text-align: center;
      color: $LightGray;
      cursor: pointer;
      @include textDesign(#dbdbdb, 20px, 500);
      .active {
        border: none;
        line-height: 25px;
        @include textDesign($HeadingColor, 20px, 500);
        border-bottom: 2px solid $greenColor;

        @media screen and (max-width: 576px) {
          @include textDesign($HeadingColor, 13px, 500);
        }
      }

      .not-active {
        border: none;
      }
      @media screen and (max-width: 576px) {
        @include textDesign(#dbdbdb, 13px, 500);
      }
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 20px;
    @include textDesign($HeadingColor, 13px, 500);
  }
}

.deactivated_patient_card {
  padding: 70px !important;
  @media screen and (max-width: 767px) {
    padding: 20px !important;
  }
  .patient_img_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      margin-bottom: 16px;
    }
    .deactive_patient_img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
    }
  }
  .radio_btn_wrapper {
    display: flex;
    flex-direction: column;
  }
  .main_btn_container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 40px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.min_width_14 {
  min-width: 14px;
}

.new-appointment-input-box .suggestionList {
  max-height: 150px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
}

.new-appointment-input-box .suggestionList li {
  padding: 8px;
  cursor: pointer;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.new-appointment-input-box .suggestionList li:hover {
  background-color: #f0f0f0;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.suggestion-name {
  font-weight: bold;
}

.suggestion-email {
  font-size: 0.9em;
  color: #666;
}
@media screen and (min-width: 768px) {
  .flex_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
  }
}
.covid_form_icon_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.covid_form_card {
  padding: 70px !important;
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
}
.edit_recall_btn {
  border: none;
  &:hover {
    background-color: transparent;
  }
}
.btn_flex_cont {
  display: flex;
  justify-content: space-between;
}
.appointment_modal_card_new {
  padding: 70px !important;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 12px;

  @media screen and (max-width: 767px) {
    padding: 20px !important;
  }
  .recall_small_card {
    background-color: #f9fcee;
    padding: 24px;
    border-radius: 10px;
    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }
}
.appointment_table_btn {
  border: none;
  background-color: transparent;
}

.edit_card {
  padding: 20px;
  background-color: #f9fcee;
  width: 50%;
}
.appointment_modal_card_new1 {
  width: 50%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .edit_reacll_container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding-bottom: 12px;
    margin-bottom: 16px;
  }
}
.flex_col_style {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.flex_col_style > *:not(:last-child) {
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
}
.modal_flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 8px;
}
.detail_btn_container {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}
.btn__container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
  margin-top: 30px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.recall_appointment_btn {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 24px;
  margin-top: 40px;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
}

.not_found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .card_new_wrapper {
    padding: 40px 15px 40px;
  }
}
.table_ui {
  width: 100%;
  overflow-x: scroll !important;
}
.patient-appointment-details-upper-card-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.btn_container_new {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.link_new {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: fit-content;
  white-space: nowrap;
}
.schedular_popup_daily_view {
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  width: 100%;
  max-width: 260px;

  @media (max-width: 480px) {
    max-width: 90vw;
    left: auto;
    right: 20px !important;
    transform: none;
  }

  .close_btn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .container_box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 6px;
    padding: 0 20px 20px 20px;

    .content_wrapper {
      background-color: #f2f3ef;
      padding: 6px 50px;
      text-align: center;
      border-radius: 4px;
    }
  }
}

.schedular_popup_invite_view {
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  width: 100%;
  max-width: 260px;

  @media (max-width: 480px) {
    max-width: 90vw;
    left: auto;
    right: 20px !important;
    transform: none;
  }
  .close_btn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .invitees_data_container {
    max-height: 178px;
    overflow-y: scroll;

    .container_box {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 8px;
      padding-top: 8px;
      align-items: center;
      gap: 16px;
      margin-left: 20px;
      margin-right: 20px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border: none;
      }

      .invitees_img {
        height: 28px;
        width: 28px;
        border-radius: 100px;
      }
    }
  }
}
.truncate_text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}
.staff_list {
  ul {
    display: table;
    width: 100%;
    li {
      float: left;
      position: relative;
      margin-left: -6px;

      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }

      &:first-child {
        margin-left: 0;
      }

      .more {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        text-align: center;
        line-height: 27px;
        background-color: #e3edc3;
        border: 1px dashed #8f9bb2;
        font-size: 11px;
        color: #8f9bb2;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
.date_container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}
.action_date {
  color: #ee4f4f;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  background-color: #f2f3ef;
  padding: 6px 8px;
  border-radius: 4px;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    white-space: pre-line;
    font-size: 10px;
    padding: 4px 6px;
  }
}
.btn_property {
  font-size: 9px;
  font-weight: 500;
  line-height: 10px;
  color: #ffffff;
  padding: 4px;
  border-radius: 4px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.delete_btn {
  @extend .btn_property;
  background-color: #ee4f4f;
  &:hover {
    background-color: darken(#ee4f4f, 10%);
  }
}

.edit_btn {
  @extend .btn_property;
  background-color: #66accf;
  &:hover {
    background-color: darken(#66accf, 10%);
  }
}
