@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin fontProperties($size: 14px, $weight: 300, $color: #000000) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.page-subheading {
  margin-top: -15px;
}
.title_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.contract-data-list {
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  @media screen and (max-width: 767px) {
    padding: 10px 20px;
    margin-top: 14px;
  }

  li {
    border-bottom: solid 0.6px #dadce0;
    padding: 15px 0;
    &:last-child {
      border: 0;
    }
    .contract-link {
      @include flex(row, space-between, center);
      text-decoration: none;
    }
    .contract-name {
      @include fontProperties(15px, 600, #102c42);
      margin: 0;
    }
    .contract-btn {
      @include fontProperties(12px, 500, #587e85);
      text-decoration: underline;
    }
  }
}
.mobile-align-center {
  @media screen and (max-width: 767px) {
    margin-top: 40px !important;
    ul {
      justify-content: center;
    }
  }
}
.employment_proof_main_wrapper_card {
  padding: 10px 70px 70px 70px !important;
  @media screen and (max-width: 991px) {
    padding: 20px !important;
  }
}

.notes_btn {
  border: none;
  padding: 4px;
  border-radius: 4px;
  line-height: 10px;
  background-color: #66accf;
  @include fontProperties(9px, 500, #fff);
}

.submitted {
  background-color: #71d5b9;
  color: #2d3245;
}

.pending {
  background-color: #fcc539;
  color: #2d3245;
}

.delivered {
  background-color: #a9cf3d;
  color: #ffffff;
}

.gridContainer {
  display: grid;
  gap: 30px;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 767px) and (max-width: 1279px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  @media (max-width: 766px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
  }
}
.employment_proof_text {
  font-size: 14px;
  font-weight: 600;
  color: #102c42;
  margin: 0;
  padding-top: 4px;
  word-break: break-word;
}

.employement_proof_card {
  background-color: #f9fcee !important;
  padding: 30px !important;

  @media (max-width: 991px) {
    padding: 16px !important;
  }

  .btn_date_dropdown_wrapper {
    @include flex(row, space-between, center);
    .btn_date_wrapper {
      @include flex(row, flex-start, center, 8px);
      .date {
        @include fontProperties(12px, 400, #6f7788);
      }
    }
    .custom_dropdown_wrapper {
      padding: 8px 20px !important;
      .dropdown_list {
        border-bottom: 1px solid #eeeff1;
        padding: 12px 0 !important;
        &:last-child {
          border-bottom: none;
        }
        .dropdown_link {
          text-decoration: none;
          margin: 0;
          @include fontProperties(13px, 400, #2d3245);
          &:hover {
            color: #a9cf3d;
          }
        }
      }
    }
  }
}

.employment_proof_form_main_wrapper_card {
  padding: 70px !important;
  @media (max-width: 991px) {
    padding: 16px !important;
    border-radius: 15px !important;
  }
  .employment_proof_form_content_container {
    width: 43.3%;
    @media (max-width: 991px) {
      width: 100%;
    }
    .radio_btn_wrapper {
      @include flex(column, flex-start, flex-start);
      margin-bottom: 30px;
    }
  }
}

.employment_proof_review_wrapper_card {
  padding: 70px !important;
  @media (max-width: 991px) {
    padding: 16px !important;
    border-radius: 15px !important;
  }
  .border {
    padding-bottom: 14px;
  }

  .text_wrapper {
    @include flex(column, flex-start, flex-start, 30px);
    margin-top: 30px;

    padding-bottom: 14px;
  }
  .review_text {
    @include fontProperties(14px, 600, #102c42);
    margin: 0;
  }
  .forms_review_grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    gap: 30px;
    margin-top: 30px;
    padding-bottom: 14px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  .name_email_wrapper {
    margin-top: 30px;
  }
  .margin {
    margin-top: 30px;
  }
}
