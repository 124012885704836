$Teal: #587e85;
$HeadingColor: #111b45;
$white: #ffffff;
$blueColor: #66accf;
$redColor: #ee4f4f;
$greenColor: #a9cf3d;
$lightGray: #6f7788;
$darkNavyBlue: #102c42;
$PrimaryText: #587e85;
$lightGreen: #f9fcee;
$LightGray: #dbdbdb;

@mixin buttonDesign(
  $textColor,
  $textSize,
  $textWeight,
  $textPadding,
  $backgroundColor,
  $borderRadius,
  $border
) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
  padding: $textPadding;
  background-color: $backgroundColor;
  border-radius: $borderRadius;
  border: $border;
}

@mixin textDesign($textColor, $textSize, $textWeight) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
}

.global-main-page {
  .account-preferences-heading {
    @include textDesign(#111b45, 25px, 500);
    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  .manage-card-container {
    flex-wrap: wrap;
    gap: 30px;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }

    .card-con {
      padding: 30px;
      width: 31%;
      min-width: 290px;
      border-radius: 15px;
      background-color: #ffffff;
      text-decoration: none;

      @media screen and (max-width: 768px) {
        margin-top: unset;
        padding: 20px;
        width: 100%;
      }

      .account-preference-card {
        border: none;
        gap: 20px;

        .account-preference-text {
          @include textDesign($PrimaryText, 16px, 600);
        }
      }
    }
  }

  .message-templates {
    .message-templates-heading {
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
      }

      .select-office {
        @include textDesign(#111b45, 25px, 500);
      }
    }

    .message-templates-card {
      padding: 30px;
      margin-bottom: 10px;

      .schedule-update {
        display: flex;
        justify-content: space-between;
      }
      .show-message {
        .show-message-box {
          margin-top: 10px;
          background-color: $lightGreen;
          padding: 15px;
          .file-icon-box {
            display: flex;
            margin-top: 18px;
            gap: 10px;

            span {
              font-size: 14px;
              font-weight: 600;
              color: #102c42;
            }
          }
        }
      }
    }

    .office-dropdown-menu {
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
      padding: 20px;
      border: none;
      border-radius: 10px;

      .office-dropdown-item {
        border-bottom: 1px solid #d8d8d8;
        font-size: 13px;
        font-weight: 400;
        padding: 12px 0;
        background-color: transparent;
        color: #111b45;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0px;
        }

        &:first-child {
          padding-top: 0px;
        }
        .office-dropdown-link {
          text-decoration: none;
        }
      }
    }

    .global-preference-form {
      .message-form-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (max-width: 767px) {
          width: 100%;
        }

        .message-form {
          background-color: #f9fcee;
          padding: 20px;
          margin-top: 40px;

          .form-input-box {
            width: 100%;
            font-size: 13px;
            font-weight: 400;
            color: #79869a;
            margin-bottom: 30px;

            .form-icon-box {
              margin-top: 18px;
              display: flex;
              justify-content: space-between;
            }
          }

          .file-input-container {
            span {
              font-size: 13px;
              font-weight: 400;
              color: #79869a;
            }

            border-bottom: 1px solid #c4c4c3;

            .file-icon-box {
              display: flex;
              margin-top: 18px;
              gap: 10px;

              span {
                font-size: 14px;
                font-weight: 600;
                color: #102c42;
              }
            }

            .attach-file-btn {
              background-color: unset;
              border: none;
              text-decoration: underline;
              font-size: 12px;
              font-weight: 500;
              color: #587e85;
            }

            .file-input-box {
              visibility: hidden;
              width: 0;
            }
          }
        }
      }
    }

    .btn-container {
      margin-top: 30px;
    }
  }
  .preview-btn {
    background-color: unset;
    border: none;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 500;
    color: #587e85;
  }
}
.details_checkbox {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 15px !important;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
}
