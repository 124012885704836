header {
  @include padding(15 100);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  background-color: $white;

  @media (max-width: 991px) {
    @include padding(15 30);
  }

  @media (max-width: 767px) {
    @include padding(15 0);
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .notify-dropdown {
      margin-right: 20px;
      cursor: pointer;
      padding: 5px;
      .dropdown-menu {
        min-width: 380px;
        @media (max-width: 767px) {
          min-width: 290px;
        }
      }
    }

    .dashboard-home-redirection-icon {
      //   margin-right: 10px;
      cursor: pointer;
      //   border-right: 1px solid red;
      padding: 5px;
    }
    .empty-notify-wrapper {
      text-align: center;
      padding-top: 48px;
      padding-bottom: 25px;
      font-weight: 300;
      font-size: 16px;
      color: #535b5f;
      img {
        margin-bottom: 30px;
      }
      .notify-heading {
        font-weight: 600;
        font-size: 18px;
        color: #102c42;
        margin-bottom: 10px;
      }
    }
    .header-notification {
      padding-top: 20px;

      ul {
        max-height: 250px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #587e85;
          border-radius: 10px;
        }

        li {
          display: flex;
          padding: 12px 10px;
          border-bottom: 1px solid #eeeff1;
          align-items: flex-start;
          &:last-child {
            border-bottom: 0;
          }
          img {
            margin-right: 10px;
            position: relative;
            top: 4px;
          }
          .content-box {
            width: calc(100% - 25px);
            .notify-text {
              font-weight: 400;
              font-size: 13px;
              color: #111b45;
              strong {
                font-weight: 500;
              }
            }
            .notify-date {
              font-weight: 400;
              font-size: 12px;
              color: #6f7788;
              margin-top: 8px;
            }
          }
        }
      }
      .see-notification {
        background-color: #fff;
        padding-top: 12px;
        border-top: 1px solid #eeeff1;
        padding-bottom: 20px;
        .link-btn {
          text-decoration: none;
        }
      }
    }
  }

  .user-dropdown {
    position: relative;
    float: right;
    .usr-info {
      cursor: pointer;
      min-width: 120px;

      @media (max-width: 767px) {
        min-width: inherit;
      }

      img.user-img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        @include margin-right(8);

        @media (max-width: 767px) {
          margin-right: 0;
          width: 35px;
          height: 35px;
        }
      }

      span {
        @include fontsize(14);
        font-weight: 500;
        color: #111b45;
      }

      .media-body {
        img {
          display: inline-block;
          margin-left: 10px;
        }
      }

      .responsive-dot-container {
        display: none;
        @media (max-width: 767px) {
          display: block;
          width: 13px;
          height: 12px;
        }
      }
    }
  }

  .dropdown-menu {
    min-width: 200px;
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
    border: 0;
    background-color: #fff;
    max-height: 86vh;
    overflow-y: auto;
    .dropdown-item {
      @include fontsize(13);
      color: #111b45;
      font-weight: 400;
      line-height: 13px;
      @include padding(0);
      background-color: #fff;
      position: relative;

      a {
        text-decoration: none;
        color: #2d3245;
        display: block;
        @include padding(14 0);
      }

      & > span {
        text-decoration: none;
        display: block;
        @include padding(14 0);
        color: #2d3245;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover,
      &:focus {
        outline: none;

        box-shadow: none;
        color: #587e85;
      }

      img {
        margin-right: 9px;
      }
    }
    .superadmin-dropdown-list {
      padding-top: 10px;
      .dropdown-group {
        border-top: 1px solid #eeeff1;
        &:first-child {
          border-top: 0;
        }
      }
      .dropdown-item {
        border: 0;
        a {
          color: #2d3245;
          padding: 8px 0;
          line-height: 1.4;
        }
      }
      + .dropdown-item {
        padding-bottom: 10px;
        span {
          color: #2d3245;
          padding: 8px 0;
          line-height: 1.23;
        }
      }
    }
  }
}
.separator_property {
  padding-left: 19px;
  padding-right: 14px;

  @media screen and (max-width: 767px) {
    padding-left: 13px;
    padding-right: 8px;
  }
}

.logout_button_style {
  border-top: 1px solid #eeeff1;
}
