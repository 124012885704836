$white: #ffffff;
$sky-blue: #66accf;
$parrot-green: #a9cf3d;
$red: #ee4f4f;
$teal: #587e85;
$border-gray: #e0e0e0;
$purple: #2d3245;
$dark-purple: #111b45;
$iron-gray: #535b5f;
$dark-blue: #102c42;
$dark-black: #000000;
$border: #f9fcee;
$card-background: #f2f3ef;

@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin fontProperties($size: 14px, $weight: 300, $color: $dark-black) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin-top: 30px;
  @media (min-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
}

.discussion_card_wrapper {
  padding: 0 !important;
}

.discussion_card_wrapper_new {
  background-color: $border !important;
  position: relative;
  padding: 0 !important;
}

.image_heading_wrapper {
  @include flex(column, center, center);
  padding: 40px 40px 28px 40px;

  .staff_name {
    width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @media (min-width: 992px) and (max-width: 1024px) {
      width: 210px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      width: 250px;
    }

    @media (max-width: 767px) {
      width: 100%;
      white-space: wrap;
    }
  }

  .profile_image {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
}

.doctor_note_wrapper {
  @include flex(row, space-between, center);
  width: 100%;
  padding: 18px 20px;
  border-top: 1px solid #dadce0;

  .doctor_wrapper,
  .note_wrapper {
    @include flex(row, center, center, 10px);
    border: none;
    .active_text {
      background-color: $white;
      @include flex(row, center, center, 10px);
    }
    .deactive_text {
      background-color: $border;
      @include flex(row, center, center, 10px);
    }
  }
  .note_wrapper_new {
    @include flex(row, center, center, 10px);
    border: none;
    .active_text {
      background-color: $white;
      @include flex(row, center, center, 10px);
    }
    .deactive_text {
      background-color: $border;
      @include flex(row, center, center, 10px);
    }
  }
}

.red_flag {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.discuss_note_details_card {
  padding: 70px !important;
  margin-top: 30px;
  height: 705px;
  @media (max-width: 991px) {
    padding: 16px !important;
    border-radius: 15px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    height: 100%;
  }

  .left_right_wrapper {
    width: 100%;
    height: 100%;
    @include flex(row, flex-start, flex-start);
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .left_part {
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .right_part {
      width: 100%;
      height: 100%;
      background-color: $card-background;
    }
  }
}

.dental_card_base {
  width: 100%;
  border-bottom: 1px solid $border-gray;
  padding: 16px;
  background-color: $white;

  @media (max-width: 991px) {
    padding: 16px 0;
  }

  .date_wrapper {
    @include flex(row, flex-start, center, 9px);

    .dot {
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: #e0e4dc;
      display: inline-block;
    }
  }

  .text_dropdown_wrapper {
    @include flex(row, flex-start, flex-start);

    @media (max-width: 767px) {
      justify-content: space-between;
      gap: 20px;
    }

    .dropdown {
      min-width: 32px;
    }

    .custom_dropdown_wrapper {
      padding: 8px 20px !important;

      .dropdown_list {
        border-bottom: 1px solid #eeeff1;
        padding: 12px 0 !important;
        &:active {
          background-color: $white;
        }

        &:last-child {
          border-bottom: none;
        }

        .dropdown_link {
          text-decoration: none;
          @include fontProperties(13px, 400, $purple);

          &:hover {
            color: $parrot-green;
          }
        }
      }
    }

    .detail_text {
      max-width: 337px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 300px;
      @include fontProperties(14px, 600, $teal);

      @media (max-width: 767px) {
        width: calc(100% - 50px) !important;
        max-width: calc(100% - 50px) !important;
      }
    }
  }
}

.dental_card_data {
  @extend .dental_card_base;
  background-color: $card-background;
}

.dental_card_data_new {
  @extend .dental_card_base;
  background-color: $white;
}

.note_details_card_wrapper {
  @include flex(column, flex-start, flex-start, 0);

  @media (min-width: 992px) {
    height: 565px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: $teal;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $teal;
    }
  }
}

.padding12 {
  padding: 0 14px;
}

.notes_detail_wrapper {
  padding: 16px;
  background-color: #f9faf6;
  @include flex(row, space-between, center, 12px);
}
.notes_detail_container {
  @include flex(column, center, center, 12px);
  height: 100%;
  width: 100%;
}
.notes_container {
  background-color: $card-background;
  height: 100%;
  padding: 30px;
  @media (max-width: 767px) {
    padding: 15px;
  }
  .notes_msg_container {
    max-height: 505px;
    overflow-y: scroll;
    background-color: $white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: $teal;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $teal;
    }
    .notes_msg {
      @include fontProperties(16px, 500, $purple);
      margin: 0;
      white-space: pre-wrap;
    }
  }
}
.delete_modal_container {
  text-align: center;
  @include flex(column, center, center, 10px);
  margin-top: 20px;
  .btn_container {
    margin-top: 30px;
    width: 75%;
    @include flex(row, center, center, 20px);

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
    .green_btn {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .white_btn {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
.title {
  line-height: 25px;
  @include fontProperties(25px, 500, $dark-purple);
}
.subtitle {
  @include fontProperties(14px, 300, $dark-black);
}
.title_subtitle_btn_wrapper {
  @include flex(row, space-between, center, 20px);
  @media (max-width: 767px) {
    @include flex(column, flex-start, flex-start, 20px);
  }
  .title_subtitle_wrapper {
    @include flex(column, flex-start, flex-start, 10px);
  }
}
.display_content {
  white-space: pre-wrap; /* CSS property to preserve white spaces and new lines */
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
}
