$blueColor: #66accf;
$redColor: #ee4f4f;
$greenColor: #a9cf3d;
$white: #ffffff;
$lightGray: #6f7788;
$darkNavyBlue: #102c42;

.appointment-table-custom-btn {
  padding: 4px 5px;
  border-radius: 4px;
  font-size: 9px;
  font-weight: 500;
  border: none;
  color: $white;
  max-height: 24px !important;
  white-space: nowrap;

  @media screen and (max-width: 767px) {
    width: unset !important;
  }
}

.blue-accepted-btn {
  background: $blueColor;
}

.red-pending-btn {
  background: $redColor;
}

.green-confirmed-btn {
  background: $greenColor;
}

.no-show-btn {
  background-color: #dae1e9;
  color: #8495aa;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: #587e85;
}

.common-history-tab {
  .history-nav-tabs {
    display: flex;
    justify-content: flex-start;

    .history-nav-item {
      max-width: fit-content;
    }

    .nav-item {
      max-width: fit-content;
    }

    li {
      max-width: fit-content;
    }

    .nav-links,
    .active,
    .not-active {
      padding-left: 0px;
      padding-right: 60px;
      max-width: fit-content;
    }

    .nav-link::before {
      left: 24%;
    }
  }
}

.edit-recall-card {
  padding: 40px 70px 70px 70px;
}

.reassign-input {
  width: 43%;
}

.test-table-box {
  height: 100%;
  .data-table-block {
    height: 100%;
    display: block;
    .data-table-container {
      height: 100%;
      .table {
        thead {
          th {
            background-color: white;
            white-space: nowrap;
            border: none;
            &:first-child {
              padding-left: 15px !important;
              &::after {
                left: 15px;
              }
            }
            &:last-child {
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              @media screen and (min-width: 992px) {
                vertical-align: top;
                -webkit-box-orient: vertical;
                white-space: nowrap;
                &:nth-child(2) {
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  max-width: 200px;
                  white-space: normal;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                &:first-child {
                  padding: 15px !important;
                  &::after {
                    left: 15px;
                  }
                }

                &:last-child {
                  padding: 15px !important;
                  text-align: center;
                  &:after {
                    left: 0px;
                  }
                }

                div span {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}

.scheduler-calendar {
  .react-calendar__tile:hover {
    abbr {
      background-color: #a9cf3d !important;
      color: #f0f0f0;
    }
  }
  .react-calendar__tile--active {
    abbr {
      background-color: #a9cf3d !important;
      color: white;
      @media screen and (max-width: 767px) {
        width: 23px;
        height: 26px;
      }
    }
  }
}

.patient_appointment_history_table .react-bootstrap-table .table thead {
  display: none;
}
.nav_tabs_wrapper {
  .nav-tabs {
    .nav-item {
      max-width: fit-content !important;
      width: fit-content !important;
      @media screen and (max-width: 767px) {
        a {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }
    }
  }
}
