.appointment-main-wrapper-one{
    .scheduler-sidebar{
        margin-top:86px; 
        @media screen and (max-width: 992px){
            margin-top:0px; 
        } 
    }

    .appointment-tab-right-col{
        @media screen and (min-width: 1800px){
            padding-left:30px;
        }
    }
}
