.appointment-tabs {
  background-color: #fff;
  padding: 25px 70px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  @media screen and (max-width: 991px) {
    padding: 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 25px 5px;
  }

  .nav-tabs {
    margin-bottom: 45px;
    border: none;

    .nav-items {
      width: 33.33%;
      text-align: center;
      color: gray;
      cursor: pointer;

      .nav-link {
        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }

      .active {
        border: none;
        line-height: 25px;
        border-bottom: 2px solid #a9cf3d;
        font-size: 20px;
        font-weight: 500;
        color: #111b45;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          font-weight: 500;
          padding: 0;
        }
      }

      .not-active {
        border: none;
        line-height: 25px;
        border-bottom: 2px solid #fff;
        font-size: 20px;
        font-weight: 500;
        color: #dbdbdb;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          font-weight: 500;
          padding: 0;
        }
      }
    }
  }
}

.branding-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-bottom: 40px;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .branding_left_container {
    border-right: 1px solid #e0e0e0;

    @media screen and (max-width: 991px) {
      border: none;
    }

    .input_wrapper {
      margin-right: 30px;
      @media screen and (max-width: 991px) {
        margin-right: 0;
      }
    }

    .button_wrapper {
      display: flex;
      gap: 20px;
    }

    .checkbox_wrapper {
      display: flex;
      gap: 25px;

      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 0px;
      }
    }

    .check_box_container {
      display: flex;
      justify-content: space-between;

      p {
        margin-bottom: 8px;
      }

      .check_box_text {
        font-size: 13px;
        font-weight: 400;
        color: #79869a;
      }
      .checkbox_preview_text {
        font-size: 12px;
        font-weight: 500;
        color: #587e85;
        text-decoration: underline;
        cursor: pointer;
      }

      .checkbox_layout {
        display: flex;
        gap: 20px;
      }
    }
  }

  .file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .upload-help-text {
      max-width: 350px;
      font-weight: 300;
      font-size: 14px;
      text-align: center;
    }
    .file-upload-field {
      position: relative;

      .ch-upload-button {
        position: absolute;
        top: 160px;
        left: 90px;
      }

      .office-image {
        img {
          height: 180px;
          width: 180px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
}

.google-radio-list {
  max-height: 250px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e0e0e0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #587e85;
    border-radius: 10px;
  }
  label {
    padding: 8px 0;
  }
  span {
    animation: none !important;
  }
}
.show-on-map {
  margin-bottom: -20px;
  position: relative;
  z-index: 1;
}
.location-input-wrapper {
  position: relative;
  .location-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0628824);
    border-radius: 10px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 3;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e0e0e0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #587e85;
      border-radius: 10px;
    }
    li {
      font-weight: 400;
      font-size: 13px;
      color: #111b45;
      padding: 10px 0px;
      margin: 0px 20px;
      border-bottom: 1px solid #eeeff1;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
