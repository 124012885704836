.page {
    min-height: 82vh;
    a {
        text-decoration: none;
    }
}

.card {
    margin:0 0 10px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    position: relative;

    h4{
        color: #587e85;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
}

.icon {
    width: 100px;
    height: 100px;

    object-fit: contain;
    object-position: center;
}

.mobile-text-size {
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 14px;
}