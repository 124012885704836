@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin fontProperties($size: 14px, $weight: 300, $color: #000000) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.p_tag {
  margin: 0;
  line-height: 20px;
  @include fontProperties(14px, 400, #000000);
}
.link_text {
  line-height: 16px;
  @include fontProperties(14px, 600, #000000);
}

.title {
  @include fontProperties(25px, 500, #111b45);
  margin: 0;
}
.sub_title {
  @include fontProperties(14px, 300, #000000);
  margin: 0;
}

.margin40 {
  margin-top: 40px;
}

.btn_date_wrapper {
  @include flex(row, flex-start, center, 8px);
}

.btn_wrapper {
  @include flex(row, flex-start, center, 10px);
}

.btn_title_wrapper {
  @include flex(row, space-between, center);
  @media screen and (max-width: 991px) {
    @include flex(column, flex-start, flex-start, 20px);
  }
}

.text_link_wrapper {
  max-width: 1110px;
  margin: 30px auto 0 auto;
  background-color: #ffba00;
  padding: 12px 20px;
  border-radius: 6px;
  @include flex(row, space-between, center);

  @media (max-width: 767px) {
    @include flex(column, flex-start, flex-start);
    padding: 20px 10px;
    margin: 15px;
  }

  .image_text_link {
    @include flex(row, flex-start, center);
    width: 100%;
    @media (max-width: 767px) {
      align-items: flex-start;
    }

    .text_wrap {
      @include flex(row, space-between, center);
      width: 100%;
      @media (max-width: 767px) {
        gap: 12px;
        @include flex(column, flex-start, flex-start, 12px);
      }
    }

    .text_link {
      flex-wrap: wrap;
      @include flex(row, flex-start, center);
    }
  }
}

.performance_review_card {
  padding: 10px 70px !important;
  margin-top: 30px;

  @media (max-width: 991px) {
    padding: 1px 20px 20px 20px !important;
    margin-top: 20px;
  }
}

.add_new_performance_card {
  padding: 70px !important;

  @media (max-width: 767px) {
    padding: 20px !important;
  }
  .card_container {
    width: 100%;
    max-width: 43.3%;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }

    @media (min-width: 767px) and (max-width: 1024px) {
      width: 70%;
      max-width: 70%;
    }

    .searchbar_wrapper {
      background-color: #f9fcee;
      padding: 20px;
      margin-top: 30px;
      .margin16 {
        margin-top: 16px;
      }
      .demo {
        height: 283px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #e0e0e0;
        }
        &::-webkit-scrollbar-thumb {
          background: #587e85;
          border-radius: 10px;
        }
        .checkbox_wrapper {
          @include flex(column, flex-start, flex-start, 16px);
          padding-right: 12px;
          margin-top: 24px;
          .checkbox_container {
            border-bottom: 1px solid #e0e0e0;
            width: 100%;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.confirmation_modal_container {
  text-align: center;
  @include flex(column, center, center, 10px);
  margin-top: 15px;
  .btn_container {
    margin-top: 30px;
    width: 75%;
    @include flex(row, center, center, 20px);

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
    .green_btn {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .white_btn {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.notes_btn {
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 18px;
  background-color: #66accf;
  @include fontProperties(10px, 500, #fff);
}

.submitted {
  background-color: #71d5b9;
  color: #ffffff;
}

.pending {
  background-color: #fcc539;
  color: #ffffff;
}

.cancelled {
  background-color: #f01919;
  color: #ffffff;
}

.completed,
.finalized {
  background-color: #a9cf3d;
  color: #ffffff;
}
.general {
  background-color: #dae1e9;
  color: #8495aa;
}
.flex_container {
  display: grid;
  gap: 30px;
  width: 100%;
  padding-left: 30px;
  @media (min-width: 1281px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 20px;
    padding-left: 0px;
  }
}
.flex_container_active {
  display: grid;
  gap: 30px;
  width: 100%;
  padding-left: 30px;
  @media (min-width: 1201px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.employement_proof_card {
  background-color: #f9fcee !important;
  padding: 20px !important;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 16px !important;
  }
}
.grid_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 25px;
  width: 100%;
  margin-top: 16px;
}
.btn_date_dropdown_wrapper {
  @include flex(row, space-between, center);
  .btn_date_wrapper {
    @include flex(row, flex-start, center, 8px);
    .date {
      @include fontProperties(12px, 400, #6f7788);
    }
  }
  .custom_dropdown_wrapper {
    padding: 8px 20px !important;
    border: none;
    .dropdown_list {
      border-bottom: 1px solid #eeeff1 !important;
      padding: 12px 0 !important;
      &:active {
        background-color: #fff;
      }
      &:last-child {
        border-bottom: none;
      }
      .dropdown_link {
        text-decoration: none;
        margin: 0;
        @include fontProperties(13px, 400, #2d3245);
        &:hover {
          color: #a9cf3d;
        }
      }
    }
  }
}

.bgYellow {
  background-color: #f9fcee !important;
}

.accordion_wrapper {
  @include flex(column, center, center, 10px);
  width: 100%;
  margin-top: 30px;
  .AccordionItem {
    background-color: #f9fcee !important;
    padding: 15px 20px;
    width: 100%;
    .AccordionItemPanel {
      border-top: 1px solid #dadce0;
      margin-top: 12px;
      padding-top: 20px;
      .name_link_wrapper {
        @include flex(row, space-between, center);
      }
    }
  }
}

.link_btn_wrapper {
  @include flex(row, flex-end, center, 24px);
  @media (max-width: 991px) {
    @include flex(row, space-between, center, 24px);
    padding-top: 30px;
  }
  .link_btn {
    border: none;
    background-color: #fff;
  }
}

.performance_review_wrapper_card {
  padding: 70px !important;
  @media (max-width: 991px) {
    padding: 20px !important;
  }

  .performance_review_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 991px) {
      flex-direction: column;
    }

    .performance_review_left_section {
      width: 50%;
      padding-right: 70px;
      @media (max-width: 991px) {
        width: 100%;
        padding: 0;
      }
    }

    .performance_review_right_section {
      width: 50%;
      border-left: 1px solid #dadce0;
      padding-left: 70px;
      @media (max-width: 991px) {
        border-left: none;
        border-top: 1px solid #dadce0;
        width: 100%;
        padding: 0;
        margin-top: 30px;
      }
    }
  }
}

.performance_review_grid_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
  margin-top: 30px;
}

.feedback_modal_container {
  .data_wrapper {
    @include flex(column, flex-start, flex-start, 16px);
    margin-top: 32px;
    .number_para_wrapper {
      @include flex(row, flex-start, center, 10px);
      .modal_text_number {
        border: 1px solid #e5e7df;
        border-radius: 50%;
        position: relative;
        padding: 4px 15px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .modal_paragraph {
      margin: 0;
      @include fontProperties(16px, 300, #535b5f);
    }
  }
}

.home_btn_wrapper {
  @include flex(row, flex-start, center, 25px);
  @media screen and (max-width: 767px) {
    @include flex(column, flex-start, flex-start, 20px);
    width: 100%;
  }
}

.home_btn_wrapper_new {
  @include flex(row, flex-start, center, 25px);
  @media screen and (max-width: 991px) {
    width: 100%;
    @include flex(column, flex-start, flex-start, 20px);
    button {
      width: 100%;
    }
  }
}

.grid_container_details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.review_details {
  @include flex(row, flex-start, center, 20px);
  margin-top: 30px;
  padding-bottom: 6px;
  .review_sent_tag {
    background-color: #f9fcee;
    padding: 20px;
  }
}
.text_element {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #6f7788;
  margin: 30px 0 0 0;
  word-break: break-word;
}

.accordion_text_element {
  @extend .text_element;
  font-size: 12px;
}

.text_data {
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  color: #102c42;
  margin: 10px 0 0 0;
  word-break: break-word;
}

.accordion_data_element {
  @extend .text_data;
  font-size: 14px;
}
.my_flex_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 20px;
}

.comment_text {
  font-size: 14px;
  font-weight: 600;
  color: #102c42;
  margin: 0;
  word-break: break-word;
}

.add_note_radio_btn {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 16px;
  column-gap: 25px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
.rating_modal_container {
  @include flex(column, flex-start, flex-start, 0);
  .content_wrapper {
    @include flex(column, flex-start, flex-start, 10px);
    margin-top: 10px;
    .content_container {
      @include flex(row, center, center, 16px);
      .content_number {
        min-width: 36px;
        min-height: 36px;
        border: 1px solid #dadce0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
      .content {
        color: #587e85;
        font-size: 14px;
      }
    }
  }
}
.home_btn_wrapper_new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  .green_btn {
    width: 50%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
