.scheduler-page {
  > .container {
    max-width: 100%;
    padding: 0 30px;
    @media screen and (min-width: 1920px) {
      max-width: 2000px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }

  .page-title {
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
  .top-right-text {
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: left;
      margin-top: 10px;
    }
    .show-text {
      color: #6f7788;
      font-size: 12px;
    }
    .notification-red-dot img {
      margin-left: 5px !important;
    }
  }
}

.scheduler-tabs {
  > .nav-tabs {
    border-bottom: 0;
    margin-bottom: 12px;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    > .nav-item {
      width: 50%;
      text-align: center;
      > .nav-link {
        font-weight: 500;
        font-size: 20px;
        text-transform: none;
        color: #ababab;
        letter-spacing: 0;
        padding: 15px 30px;

        @media screen and (max-width: 767px) {
          font-size: 13px;
          padding: 12px 6px;
        }
        &::before {
          background-color: #a9cf3d;
          left: 0;
          width: 100%;
          margin-left: 0;
          height: 2px;
          border-radius: 0;
        }
        &.active {
          color: #111b45;
        }
      }
    }
  }
  .tab-content {
    .common-tabs {
      @media screen and (max-width: 767px) {
        .nav-tabs {
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: flex-start;
          height: 57px;
          &::-webkit-scrollbar {
            display: none;
          }
          .nav-link {
            padding: 21px 10px;
            letter-spacing: 0.2px;
            white-space: nowrap;
            &::before {
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

/*filter-accordion*/
.filter-accordion {
  .accordion__item {
    margin: 20px 0;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
    padding-right: 4px;
    &:first-child {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }
  .accordion__button {
    background: transparent;
    padding: 8px 0;
    color: #6f7788;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    &::before {
      height: 10px;
      width: 10px;
      margin-right: 0;
      transform: rotate(45deg);
      border-color: #77928b;
    }
    &[aria-expanded="true"]::before {
      transform: rotate(-135deg);
    }
  }
  .accordion__panel {
    padding: 0 3px 0 0;
  }
}
// tabs-main-wrapper
.scheduler-tabs-main-wrapper {
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 25px;
  }
  .sidebar-active {
    + .tabs-right-col {
      width: calc(100% - 270px);
      @media screen and (min-width: 1800px) {
        width: calc(100% - 366px);
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .table-responsive {
        max-width: 564px;
        max-height: 750px;
      }
    }
  }
  .tabs-right-col {
    padding-left: 30px;
    width: calc(100%);
    overflow: hidden;
    @media screen and (min-width: 1800px) {
      padding-left: 70px;
    }
    @media screen and (max-width: 991px) {
      padding-left: 30px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }

    .table-card-border {
      border: 1px solid #e5e7df;
      height: calc(100% - 75px);
    }
    .select-control {
      &:after {
        top: 9px;
      }
    }
  }
  .scheduler-sidebar {
    max-height: 850px;
    @media screen and (max-width: 991px) {
      max-height: 800px;
    }
  }
  &.agenda-daily-view {
    .scheduler-sidebar {
      max-height: 770px;
    }
    .agenda-fixed-table {
      height: calc(100% - 115px);
      max-height: 695px;
    }
  }
  .inputdate {
    cursor: pointer;
    input {
      cursor: pointer;
    }
  }
}
.scheduler-sidebar {
  .filter-btn-box {
    display: flex;
    .cancel-btn {
      display: none;
    }
    @media screen and (max-width: 767px) {
      margin: 0;
      justify-content: space-around;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.083998);
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px 15px;
      z-index: 11;
      .reset-btn {
        display: none;
      }
      .cancel-btn {
        display: block;
      }
    }
    .button {
      min-width: 110px;
      // line-height: 31px;
      min-height: 35px;
      padding: 6px 30px;
      @media screen and (min-width: 1800px) {
        min-width: 130px;
      }

      @media screen and (max-width: 767px) {
        width: 47%;
      }
    }
  }
}
@media (max-width: 767px) {
  .schedular-sidebar-active {
    .scheduler-page {
      height: 0;
      overflow: hidden;
    }
  }
}
.scheduler-empty-box {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 0;
  p {
    line-height: 1.3;
    @media (max-width: 767px) {
      font-size: 18px !important;
    }
  }
}
.staff-calendar-select-box {
  @media screen and (max-width: 991px) {
    justify-content: space-between;
  }
  .c-field {
    margin-bottom: 0;
    label {
      display: none;
    }
  }
  .select-control:after {
    top: 9px;
  }
}
.monthly-calendar-arrows {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: #111b45;
  align-items: center;
  margin-right: 20px;
  position: relative;
  @media screen and (max-width: 1200px) {
    margin-right: 10px;
  }
  .monthly-calendar-text {
    margin: 0 5px;
    text-align: center;
    white-space: nowrap;
    @media screen and (max-width: 1200px) {
      font-size: 11px;
    }
  }
  .arrow-img {
    width: 30px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
  }
  .arrow-img-dashboard {
    width: 20px;
    cursor: pointer;
    text-align: center;
  }
}
.select-common-dropdown {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 92px;
  .selected-item {
    font-size: 14px;
    color: #111b45;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    gap: 12px;
    @media screen and (max-width: 1200px) {
      font-size: 11px;
    }

    img {
      margin-left: 10px;
      width: 8px;
      @media screen and (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }
  .dropdown-menu {
    min-width: 160px;
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
    border: 0;
    background-color: #fff;
    .dropdown-item {
      font-size: 13px;
      color: #111b45;
      font-weight: 400;
      line-height: 13px;
      padding: 0px;
      background-color: #fff;
      position: relative;
      border-bottom: 1px solid #f2f3ef;
      outline: 0;
      a,
      span {
        text-decoration: none;
        display: block;
        padding: 14px 0px;
        outline: 0;
        color: #535b5f;
      }
    }
  }
}

.custom-dropdown-only {
  .select-common-dropdown {
    .selected-item {
      width: 100% !important;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: solid 1px rgba(151, 151, 151, 0.4);
      padding-bottom: 15px;
      box-shadow: none;
      min-height: 37px;
      color: #102c42;
      padding-left: 0;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: 600;
      justify-content: space-between;
    }
    .dropdown-menu {
      transform: none !important;
      width: 100%;
      max-height: 220px;
      margin-top: 21px !important;
      overflow-y: scroll;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        width: 3px;
        height: 5px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #e0e0e0;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #587e85;
        border-radius: 10px;
      }
    }
  }
}
