$white: #ffffff;
$sky-blue: #66accf;
$parrot-green: #a9cf3d;
$red: #ee4f4f;
$teal: #587e85;
$border-gray: #e0e0e0;
$purple: #2d3245;
$dark-purple: #111b45;
$iron-gray: #535b5f;
$dark-blue: #102c42;

@mixin flex($direction: row, $justify: center, $align: center, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin textDesign($textColor, $textSize, $textWeight) {
  color: $textColor;
  font-size: $textSize;
  font-weight: $textWeight;
}

.notes_main_parent_wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.notes_card_wrapper_base {
  padding: 30px 30px 16px 30px !important;
  @include flex(column, flex-start, stretch, 16px);

  .dropdown_wrapper {
    @include flex(row, space-between);
    .btn_date_wrapper {
      @include flex(row, space-between, center, 8px);
      @media screen and (max-width: 767px) {
        @include flex(column, space-between, flex-start, 4px);
      }

      .notes_btn {
        border: none;
        padding: 4px;
        border-radius: 4px;
        line-height: 10px;
        background-color: $sky-blue;
        @include textDesign($white, 9px, 500);
      }

      .new {
        background-color: $sky-blue;
        color: $white;
      }

      .seen {
        background-color: #dae1e9;
        color: #8495aa;
      }

      .delivered {
        background-color: $parrot-green;
        color: $white;
      }
      .notes_date {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  .office_doctor_wrapper_base {
    @include flex(row, space-between, flex-start, 30px);
    padding-bottom: 16px;

    .office_name_data {
      max-width: 104px;
      .break_word {
        word-break: break-word;
      }
    }

    .image_no_reminder {
      @include flex(row, flex-start, center, 8px);
    }

    .notification_text {
      @include textDesign($red, 12px, 300);
    }
  }

  .office_doctor_wrapper {
    @extend .office_doctor_wrapper_base;
    border-bottom: 1px solid $border-gray;
  }

  .office_doctor_wrapper_new {
    @extend .office_doctor_wrapper_base;
  }

  .patient_list {
    line-height: 18px;
    text-decoration: none;
    word-break: break-word;
    @include textDesign($teal, 16px, 600);
  }
}

.notes_card_wrapper_active {
  @extend .notes_card_wrapper_base;
  width: 47.5%;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 16px !important;
  }

  @media screen and (min-width: 768px) and (max-width: 1115px) {
    width: 100%;
  }
}

.notes_card_wrapper_not_active {
  @extend .notes_card_wrapper_base;
  width: 31.2%;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 16px !important;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 48%;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 47.9%;
  }
}

.notes_card_grid {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding-left: 46px;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding-left: 32px !important;
    gap: 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    gap: 20px;
  }
}

.notes_main_card {
  padding: 10px 70px 70px 70px !important;

  @media screen and (max-width: 991px) {
    padding: 10px 15px 20px !important;
    border-radius: 10px !important;
  }
}

.custom_dropdown_wrapper {
  padding: 8px 20px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  .dropdown_list {
    border-bottom: 1px solid $border-gray;
    padding: 12px 0 !important;
    &:active {
      background-color: $white;
    }

    &:last-child {
      border: none;
    }
    .dropdown_link {
      text-decoration: none;
      @include textDesign($purple, 13px, 400);

      &:hover {
        color: $parrot-green;
      }
    }
  }
}

.custom_modal_body {
  text-align: center;
  @include flex(column, center, center);

  .modal_heading {
    line-height: 25px;
    margin-top: 30px;
    @include textDesign($dark-purple, 25px, 500);

    @media screen and (max-width: 767px) {
      margin-top: 10px;
      @include textDesign($dark-purple, 20px, 500);
    }
  }

  .paragraph_wrapper {
    margin-top: 3px;
    .modal_data {
      line-height: 24px;
      max-width: 485px;
      @include textDesign($iron-gray, 16px, 300);

      @media screen and (max-width: 767px) {
        line-height: 20px;
        @include textDesign($iron-gray, 12px, 400);
      }
    }
  }

  .delete_modal_container {
    width: 75%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;

    @media (max-width: 767px) {
      width: 100%;
      flex-direction: column;
      margin-top: 20px;
    }
    .green_btn {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .white_btn {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.notes_heading_btn_wrapper {
  margin-bottom: 30px;
  @include flex(row, space-between, center);

  @media screen and (max-width: 767px) {
    @include flex(column, space-between, flex-start, 20px);
    margin-bottom: 20px;
  }
  .modal_heading {
    line-height: 25px;
    @include textDesign($dark-purple, 25px, 500);
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      @include textDesign($dark-purple, 20px, 500);
      line-height: 25px;
      margin-top: 0;
    }
  }
}

.add_note_card {
  padding: 70px !important;
  border-radius: 10px !important;

  @media screen and (max-width: 991px) {
    padding: 16px !important;
  }

  .add_note_form_wrapper {
    max-width: 420px;

    .height60 {
      height: 60px;
    }

    .change_staff {
      border-bottom: 1px solid rgba(151, 151, 151, 0.4);
      padding-bottom: 15px;

      .changed_staff_name_wrapper {
        background-color: #f9fcee;
        padding: 20px;
        margin-top: 15px;
        .changed_staff_name {
          @include textDesign($dark-blue, 14px, 600);
        }
      }
      .add_note_radio_btn_wrapper {
        @include flex(row, space-between, flex-end);
        .disable_opacity {
          opacity: 50%;
        }

        .add_note_radio_btn {
          display: flex;
          flex-direction: column;
          @include flex(column, flex-start, flex-start);
        }
      }
    }
  }

  .addnote_calendar_box {
    @include flex(row, space-between, flex-start, 20px);
    .datpicker_width {
      width: 50%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }
}

.heading-data-Wrapper {
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  margin-top: 30px;

  .file_upload_wrapper {
    @include flex(row, space-between, center);
    margin-bottom: 16px;
    margin-top: 8px;
  }

  .trash_image {
    height: 18px;
    width: 18px;
  }

  @media screen and (max-width: 767px) {
    width: 100% !important;
    display: block;
  }

  .head-link-wrapper {
    @include flex(row, space-between, flex-start);
    .appointment-head {
      @include textDesign(#79869a, 13px, 400);
    }

    .appointment-link {
      @include textDesign($teal, 12px, 500);
      border: none;
      text-decoration: underline;
      background-color: white;
    }
  }

  .appointment-text {
    @include textDesign($dark-blue, 14px, 600);
  }
}

.staff_popup_wrapper {
  border-bottom: 1px solid $border-gray;
}

.modal_heading {
  @include textDesign($dark-purple, 25px, 500);
  line-height: 25px;
  margin-top: 30px;

  @media screen and (max-width: 767px) {
    @include textDesign($dark-purple, 20px, 500);
    line-height: 25px;
    margin-top: 0;
  }
}

.note_detail_container {
  max-width: 40%;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

  .notification_change {
    @include flex(column, flex-start, flex-start, 10px);
    margin-top: 30px;
    .reminder_change {
      @include flex(row, flex-start, center, 8px);
      width: 100%;
    }
  }

  .margin_top {
    @include flex(column, flex-start, flex-start, 10px);
    margin-top: 30px;
    .download_btn {
      border: none;
      background-color: $white;
      text-align: left;
    }
  }

  .btn_date_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;

    .notes_btn {
      border: none;
      padding: 4px 8px;
      border-radius: 4px;
      line-height: 18px;
      background-color: $sky-blue;
      @include textDesign($white, 12px, 500);
    }

    .new {
      background-color: $sky-blue;
      color: $white;
    }

    .seen {
      background-color: #dae1e9;
      color: #8495aa;
    }

    .delivered {
      background-color: $parrot-green;
      color: $white;
    }
    .notes_date {
      font-size: 12px;
      font-weight: 300;
    }
  }
  .office_doctor_wrapper {
    @include flex(row, space-between, flex-start, 30px);
    padding-bottom: 30px;
    margin-top: 30px;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    .office_name_data {
      max-width: 227px;
      .break_word {
        word-break: break-word;
      }
    }

    .notification_text {
      @include textDesign($red, 12px, 300);
    }
  }
}

.addnote_calendar_box {
  @include flex(row, space-between, flex-start, 20px);
  margin-top: 30px;
  width: 100%;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    width: 100%;
  }

  .datpicker_width {
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}
.notes_filter {
  position: relative;
  @include textDesign($dark-blue, 15px, 600);
  .red_dot {
    position: absolute;
    height: 6px;
    width: 6px;
    background-color: $red;
    border-radius: 50%;
    top: 0;

    @media (max-width: 767px) {
      top: 8%;
    }
  }
}

.not_found {
  @include flex(row, center, center);
  width: 100%;
}

.new_search_bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 260px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 7px 10px;
  margin-left: auto;
  margin-top: 30px;
  @media (max-width: 767px) {
    margin-top: 20px;
    max-width: 100%;
  }
}

.modal_search_bar {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 7px 10px;
  margin-left: auto;
  margin-top: 30px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
}

.search_input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 10px;
  max-width: calc(100% - 20px);
}

.search_input::placeholder {
  color: #979d9f;
}

.search_icon {
  @include flex(row, center, center, 20px);
  padding: 5px;
}

.search_icon img {
  width: 16px;
  height: 16px;
}

@media (max-width: 767px) {
  .search-bar {
    margin-bottom: 20px;
  }
}
.main_btn_container {
  @include flex(row, flex-start, flex-start, 25px);
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    margin-top: 30px;
  }
}

.alert_modal {
  max-width: 350px;
  .alert {
    @include flex(column, center, center, 0);
  }

  .btn_container {
    @extend .alert;
    border-top: 1px solid $border-gray;
    padding-top: 30px;
    width: 100%;
  }
}
