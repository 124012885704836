$border-gray: #e0e0e0;
$card-yellow: #f9fcee;
$dark-black: #000000;

.card_container {
  padding: 25px 20px;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  cursor: pointer;

  .title_number_wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
.icon {
  min-width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
}
.link_container {
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
}

.appointment_section_wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.dashboard_main_wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .title_date_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }

    .date_wrapper {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }
}

@mixin textDesign($textSize, $textWeight, $textColor) {
  font-size: $textSize;
  font-weight: $textWeight;
  color: $textColor;
}

.sub_title {
  @include textDesign(14px, 300, $dark-black);
  margin: 0;
}

.dashboard_grid_container {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);

  @media (min-width: 992px) and (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
