@use "TeamConversation.scss";

.patient_live_chat_page_content {
  .messenger-page-content {
    margin-top: 10px;
    .active span {
      animation: none;
    }
    .container {
      max-width: 1170px;
      @media screen and (min-width: 1800px) {
        max-width: 2200px;
        padding-left: 100px;
        padding-right: 100px;
      }
    }
  }
  .team_chat_card {
    margin-top: 30px;
    padding: 70px 60px !important;
    margin-bottom: 50px !important;
    @media screen and (max-width: 767px) {
      padding: 10px 20px 20px !important;
      margin-bottom: 30px !important;
    }
  }
  .sendbird-notification {
    display: none !important;
  }
  .sendbird-connection-status {
    display: flex;
    font-size: 12px;
  }
}
.call_history_row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  padding-top: 16px;
  &:last-child {
    border-bottom: none;
  }
  .image_details_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    .image_container {
      height: 40px;
      width: 40px;
      img {
        height: 40px;
        width: 40px;
        border-radius: 10px;
      }
    }
    .details_wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .call_icon_content {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        .tooltip_icon {
          height: 14px;
          width: 14px;
          cursor: pointer;
        }
        .patient_name {
          font-size: 14px;
          font-weight: 600;
          color: #102c42;
          line-height: 14px;
          margin: 0;
        }
        .patient_office_name {
          font-size: 12px;
          font-weight: 400;
          color: #87928d;
          line-height: 12px;
          margin: 0;
          img {
            margin: 0px 5px 1px 5px;
          }
        }
      }
    }
  }
}
.searchbar_dropdown_wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 24px;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse !important;
    gap: 1px;
  }
}
.patient_office_email_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.5px;
  margin-left: 16px;
}
.call_history_scroll {
  overflow: auto;
  height: 300px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #587e85;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #587e85;
  }
}
