.data-table-block .data-table-container {
  box-shadow: unset;
}

.pms_subtitle {
  color: #000;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0 !important;
}

.pms_heading {
  color: #111b45;
  font-size: 25px;
  font-weight: 600;
  line-height: 25px;
}

.header_disabled_btn {
  background-color: #ebebeb;
  color: #b8b8b8;
  border: 2px solid #ddd;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  pointer-events: none;
  white-space: nowrap;

  &:hover {
    background-color: #ebebeb;
    text-decoration: none;
    outline: none;
    color: #b8b8b8;
  }
}

.table thead tr th::after {
  left: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #dadce0;
}

th {
  position: relative;
}

.ch-checkbox {
  span {
    min-height: 18px;
  }
}
