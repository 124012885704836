.react-select-container {
  width: 159px;

  @media (max-width: 991px) {
    width: 100%;
  }

  .react-select__control {
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
    background-color: white;
    border: 1px solid #dadada;
    font-weight: 500;
    width: 100%;
    min-height: 50px;

    @media (max-width: 767px) {
      min-height: 50px;
    }
  }
  .react-select__menu-list {
    min-width: 185px;
  }
  .react-select__menu {
    min-width: 185px;
  }
  .react-select__single-value {
    color: #77928b;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding: 0;
    margin-right: 15px;
    color: #77928b;
  }
  .react-select__option {
    .creation-date-option::before,
    .due-date-option::before {
      content: "";
      background-image: url("../../../assets/images/check_green_icon.svg");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 12px 12px;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }
}
.title {
  font-size: 25px;
  font-weight: 500;
  color: #111b45;
}

.task_completion_wrapper {
  width: 100%;
  .task_completion {
    display: flex;
    width: 100%;
    gap: 20px;
    .task_completion_input {
      width: 50%;
    }
  }
}
.task_assignee {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
